import React from "react";

const Projects = () => {
  return (
    <section className="project_section">
      <div className="page-header-content">
        <div className="container">
          <div className="page-header-content-inner">
            <div className="page-title" style={{ textTransform: "capitalize" }}>
              <h2>Hospital Projects</h2>
            </div>
            <ol className="breadcrumb">
              <li>
                <a href="index.jsx">Home -</a>
              </li>
              <li className="active">Hospital Projects</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
