import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import dr from "../assets/images/team/dr.png";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import anasthecia from "../assets/images/departments/anasthecia.jpg";
import dental from "../assets/images/departments/dental.jpg";
import gynayc from "../assets/images/departments/gynayc.jpg";
import icu from "../assets/images/departments/icu.jpg";
import medicine from "../assets/images/departments/medicine.jpg";
import obsterics from "../assets/images/departments/obsterics.jpg";
import ortho from "../assets/images/departments/ortho.jpg";
import pediatrics from "../assets/images/departments/pediatrics.jpg";
import physiotherapy from "../assets/images/departments/physiotherapy.jpg";
import surgery from "../assets/images/departments/surgery.jpg";
import Aos from "aos";
import "../assets/styles/Department.css";
import axios from "axios";

const DepartmentDetail = () => {
  const { slug } = useParams();
  const [selected, setSelected] = useState(null);
  const [activeSection, setActiveSection] = useState("exp");
  const [data, setData] = useState({});
  const [categorydata, setCategory] = useState({});

  const departments = [
    {
      name: "Orthopaedic",
      img: ortho,
    },
    {
      name: "Obstetrics",
      img: obsterics,
    },
    {
      name: "Gynaecology",
      img: gynayc,
    },
    {
      name: "General surgery",
      img: surgery,
    },
    {
      name: "Regenerative Medicine",
      img: medicine,
    },
    {
      name: "Anaeasthesiology",
      img: anasthecia,
    },
    {
      name: "Physiotherapy",
      img: physiotherapy,
    },
    {
      name: "Dental",
      img: dental,
    },

    {
      name: "Pediatrics",
      img: pediatrics,
    },
    {
      name: "ICU and NICU",
      img: icu,
    },
  ];

  const faqs = [
    {
      question: "What is orthodontics?",
      answer:
        "Orthodontics is a branch of dentistry that focuses on the diagnosis, prevention, and treatment of dental and facial irregularities, primarily malocclusions (improper bites), through the use of braces, clear aligners, and other orthodontic appliances.",
    },
    {
      question: "What are malocclusions?",
      answer:
        "Malocclusions are misalignments of the teeth or improper relationships between the teeth of the upper and lower dental arches.",
    },
    {
      question: "When should I consider orthodontic treatment?",
      answer:
        "Orthodontic treatment can be considered at any age, but it is typically most effective during childhood and adolescence when the teeth and jaws are still developing. ",
    },
  ];

  const toogle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const changeSpecialization = (slug) => {
    setActiveSection(slug);
  };

  useEffect(() => {
    let baseurl = process.env.REACT_APP_API_URL;
    axios
      .get(baseurl + `/v1/department/` + slug)
      .then((response) => {
        setData(response.data?.data);
        console.log(response.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(baseurl + `/v1/category/` + slug)
      .then((response) => {
        setCategory(response.data?.data);
        console.log(response.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // getDepartments();
    Aos.init({ duration: 2000 });
  }, [slug]);

  return (
    <>
      <section className="page-header-section service-title style-1">
        <div className="page-header-content">
          <div className="container">
            <div className="treatment_banner_wrapper">
              <div className="page-header-content-inner">
                <div
                  className="page-title"
                  style={{ textTransform: "capitalize" }}
                >
                  <h2>{categorydata?.name}</h2>
                </div>
                <Link to="#" className="lab-btn animation lg_size_btn">
                  <span>
                    Enquiry Now
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </span>
                </Link>
              </div>
              <div className="treatment_intro">
                <img
                  src={categorydata?.image2}
                  alt=""
                  className="banner_image"
                />
                <img src={categorydata?.image2} alt="" className="resp_image" />
              </div>
            </div>

            <div className="treat_intro_list">
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                onSlideChange={() => console.log("slide change")}
                modules={[Autoplay, Pagination, Navigation]}
                onSwiper={(swiper) => console.log(swiper)}
              >
                <SwiperSlide>
                  <div class="feature-block">
                    <div class="title">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Pariatur, adipisci?
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div class="feature-block">
                    <div class="title">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Quos praesentium porro iure repudiandae quisquam dicta.
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div class="feature-block">
                    <div class="title">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Quos praesentium porro iure repudiandae quisquam dicta.
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <Link to="#" className="lab-btn animation sm_size_btn">
              <span>
                Enquiry Now
                <i className="fa fa-angle-double-right" aria-hidden="true"></i>
              </span>
            </Link>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-section style-1 padding-tb">
        <div className="container">
          <div className="section-wrapper">
            {data?.data?.map((row, index) => {
              return (
                <div
                  className="service-item animation"
                  data-wow-duration="1s"
                  data-wow-delay=".1s"
                  data-aos="fade-up"
                >
                  <div className="service-inner">
                    <div className="service-thumb">
                      <img src={row?.feature_image} alt="service" />
                    </div>
                    <div className="service-content bg-color">
                      <h4 className="roboto-thin">
                        <Link to={`treatment/${row.slug}`}>{row.title}</Link>
                      </h4>
                      <p className="roboto-thin">{row?.short_desc}</p>
                      <Link to={`treatment/${row.slug}`} className="lab-btn">
                        <span>
                          Read More
                          <i
                            className="fa fa-angle-double-right"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* <div
              className="service-item animation"
              data-wow-duration="1s"
              data-wow-delay=".2s"
              data-aos="fade-left"
              >
              <div className="service-inner">
                <div className="service-thumb">
                  <img src={jointDisorder} alt="service" />
                </div>
                <div className="service-content">
                  <h4 className="roboto-thin">
                    <a href="#">Joint Disorders</a>
                  </h4>
                  <p className="roboto-thin">
                    Cabor levera then andin the Qualit bwdh then covae thm Uabor
                    evera then andin meqe Any value cordin
                  </p>
                  <Link
                    to={`treatment/${"joint - disorder"}`}
                    className="lab-btn"
                  >
                    <span>
                      Read More
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div> */}
            {/* <div
              className="service-item animation"
              data-wow-duration="1s"
              data-wow-delay=".3s"
              data-aos="fade-right">
              <div className="service-inner">
                <div className="service-thumb">
                  <img src={softInjury} alt="service" />
                </div>
                <div className="service-content">
                  <h4 className="roboto-thin">
                    <a href="#">Soft Tissue Injuries</a>
                  </h4>
                  <p className="roboto-thin">
                    Cabor levera then andin the Qualit bwdh then covae thm Uabor
                    evera then andin meqe Any value cordin
                  </p>
                  <Link
                    to={`treatment/${"soft - tissue - injury"}`}
                    className="lab-btn"
                  >
                    <span>
                      Read More{" "}
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="service-item animation"
              data-wow-duration="1s"
              data-wow-delay=".4s"
              data-aos="fade-up">
              <div className="service-inner">
                <div className="service-thumb">
                  <img src={sportInjury} alt="service" />
                </div>
                <div className="service-content">
                  <h4 className="roboto-thin">
                    <a href="#">Sports Injuries</a>
                  </h4>
                  <p className="roboto-thin">
                    Proced arrain manu produc rather conve quvat mantan this
                    conven multscplinari testin motin was procedur aamng proced
                    arrain manu produc rather conve quvat mantan this
                    convenmultscplinari testiners motin was procedur arraming
                  </p>
                  <Link
                    to={`treatment/${"sports - injury"}`}
                    className="lab-btn"
                  >
                    <span>
                      Read More{" "}
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="service-item animation"
              data-wow-duration="1s"
              data-wow-delay=".1s"
              data-aos="fade-right">
              <div className="service-inner">
                <div className="service-thumb">
                  <img src={spineCondition} alt="service" />
                </div>
                <div className="service-content bg-color">
                  <h4 className="roboto-thin">
                    <a href="#">Spine Conditions</a>
                  </h4>
                  <p className="roboto-thin">
                    Proced arrain manu produc rather conve quvat mantan this
                    conven multscplinari testin motin was procedur aamng proced
                  </p>
                  <Link
                    to={`treatment/${"spine-conditions"}`}
                    className="lab-btn"
                  >
                    <span>
                      Read More{" "}
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="service-item animation"
              data-wow-duration="1s"
              data-wow-delay=".2s"
              data-aos="fade-left">
              <div className="service-inner">
                <div className="service-thumb">
                  <img src={backNeckPain} alt="service" />
                </div>
                <div className="service-content">
                  <h4 className="roboto-thin">
                    <a href="#">Back and Neck Pain</a>
                  </h4>
                  <p className="roboto-thin">
                    Cabor levera then andin the Qualit bwdh then covae thm Uabor
                    evera then andin meqe Any value cordin
                  </p>
                  <Link
                    to={`treatment/${"back-and-neck-pain"}`}
                    className="lab-btn"
                  >
                    <span>
                      Read More{" "}
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="service-item animation"
              data-wow-duration="1s"
              data-wow-delay=".3s"
              data-aos="fade-right">
              <div className="service-inner">
                <div className="service-thumb">
                  <img src={orthopedicOncology} alt="service" />
                </div>
                <div className="service-content">
                  <h4 className="roboto-thin">
                    <a href="#">Orthopedic Oncology</a>
                  </h4>
                  <p className="roboto-thin">
                    Cabor levera then andin the Qualit bwdh then covae thm Uabor
                    evera then andin meqe Any value cordin
                  </p>
                  <Link
                    to={`treatment/${"orthopedic - oncology"}`}
                    className="lab-btn"
                  >
                    <span>
                      Read More{" "}
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="service-item animation"
              data-wow-duration="1s"
              data-wow-delay=".4s"
              data-aos="fade-up">
              <div className="service-inner">
                <div className="service-thumb">
                  <img src={pediOrtho} alt="service" />
                </div>
                <div className="service-content">
                  <h4 className="roboto-thin">
                    <a href="#">Pediatric Orthopedics</a>
                  </h4>
                  <p className="roboto-thin">
                    Proced arrain manu produc rather conve quvat mantan this
                    conven multscplinari testin motin was procedur aamng proced
                    arrain manu produc rather conve quvat mantan this
                    convenmultscplinari testiners motin
                  </p>
                  <Link
                    to={`treatment/pediatric-orthopedics`}
                    className="lab-btn"
                  >
                    <span>
                      Read More{" "}
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="service-item animation"
              data-wow-duration="1s"
              data-wow-delay=".1s"
              data-aos="fade-up">
              <div className="service-inner">
                <div className="service-thumb">
                  <img src={handCondition} alt="service" />
                </div>
                <div className="service-content bg-color">
                  <h4 className="roboto-thin">
                    <a href="#">Hand and Upper Extremity Conditions</a>
                  </h4>
                  <p className="roboto-thin">
                    Proced arrain manu produc rather conve quvat mantan this
                    conven multscplinari testin motin was procedur aamng proced
                    arrain manu produc rather conve quvat mantan this
                    convenmultscplinari
                  </p>
                  <Link
                    to={`treatment/hand-upper-extremity-condition`}
                    className="lab-btn"
                  >
                    <span>
                      Read More{" "}
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="service-item animation"
              data-wow-duration="1s"
              data-wow-delay=".2s"
              data-aos="fade-left">
              <div className="service-inner">
                <div className="service-thumb">
                  <img src={orthoRehibilation} alt="service" />
                </div>
                <div className="service-content">
                  <h4 className="roboto-thin">
                    <a href="#">Orthopedic Rehabilitation</a>
                  </h4>
                  <p className="roboto-thin">
                    Cabor levera then andin the Qualit bwdh then covae thm Uabor
                    evera then andin meqe Any value cordin
                  </p>
                  <Link
                    to={`treatment/orthopedic-rehabilitation`}
                    className="lab-btn"
                  >
                    <span>
                      Read More{" "}
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="service-item animation"
              data-wow-duration="1s"
              data-wow-delay=".3s"
              data-aos="fade-right">
              <div className="service-inner">
                <div className="service-thumb">
                  <img src={orthopedicInfections} alt="service" />
                </div>
                <div className="service-content">
                  <h4 className="roboto-thin">
                    <a href="#">Orthopedic Infections</a>
                  </h4>
                  <p className="roboto-thin">
                    Cabor levera then andin the Qualit bwdh then covae thm Uabor
                    evera then andin meqe Any value cordin
                  </p>
                  <Link
                    to={`treatment/orthopedic-infections`}
                    className="lab-btn"
                  >
                    <span>
                      Read More{" "}
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="service-item animation"
              data-wow-duration="1s"
              data-wow-delay=".4s"
              data-aos="fade-up">
              <div className="service-inner">
                <div className="service-thumb">
                  <img src={orthopedicTraumatology} alt="service" />
                </div>
                <div className="service-content">
                  <h4 className="roboto-thin">
                    <a href="#">Orthopedic Traumatology</a>
                  </h4>
                  <p className="roboto-thin">
                    Proced arrain manu produc rather conve quvat mantan this
                    conven multscplinari testin motin was procedur aamng proced
                  </p>
                  <Link
                    to={`treatment/orthopedic-traumatology`}
                    className="lab-btn"
                  >
                    <span>
                      Read More{" "}
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* service section */}
      {/* Team */}
      <section className="doctor-section padding-tb">
        <div className="container">
          <div
            className="section-header wow animate__animated animate__fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".1s"
          >
            <h2>
              <span>Meet Our</span>
            </h2>
            <h2>AOMC Professional Doctors</h2>
          </div>
          <div className="doctor_team_section">
            <div className="doctor_card">
              <div className="doc_header">
                <img
                  src={dr}
                  alt=""
                  style={{ width: "80px", height: "80px" }}
                />
                <div className="doc_desc">
                  <h3>Dr.P.K.JHA</h3>
                  <div class="doc-designation">
                    Consultant Orthopedic Surgeon
                  </div>
                  <div class="doc-specialization">
                    <p>Truma Surgeon</p>
                    <a href="/doctor-detail">View Profile</a>
                  </div>
                  <div class="doc-location font600">
                    <svg
                      width="14"
                      height="17"
                      viewBox="0 0 14 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 7.16699C13 11.8337 7 15.8337 7 15.8337C7 15.8337 1 11.8337 1 7.16699C1 5.57569 1.63214 4.04957 2.75736 2.92435C3.88258 1.79913 5.4087 1.16699 7 1.16699C8.5913 1.16699 10.1174 1.79913 11.2426 2.92435C12.3679 4.04957 13 5.57569 13 7.16699Z"
                        stroke="#444545"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M7 9.16699C8.10457 9.16699 9 8.27156 9 7.16699C9 6.06242 8.10457 5.16699 7 5.16699C5.89543 5.16699 5 6.06242 5 7.16699C5 8.27156 5.89543 9.16699 7 9.16699Z"
                        stroke="#444545"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    Patna
                  </div>
                </div>
              </div>
              <div class="doc-expertise">
                <div class="speciality-expertise-title doc-tabs-anchor font500">
                  <Link
                    href="#expertise-tab"
                    class={activeSection === "exp" ? "active" : "anchor"}
                    onClick={() => changeSpecialization("exp")}
                  >
                    Specialization and Expertise
                  </Link>
                  <Link
                    href="#qualification-tab"
                    class={
                      activeSection === "qualification" ? "active" : "anchor"
                    }
                    onClick={() => changeSpecialization("qualification")}
                  >
                    Qualification
                  </Link>
                </div>
                <div class="doc-tabs-holder mb-30">
                  <div class="doc-tab active" id="expertise-tab">
                    {activeSection === "exp" && (
                      <ul>
                        <li>
                          <span class="icon">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 6.45143V7.00343C12.9993 8.29729 12.5803 9.55624 11.8056 10.5925C11.0309 11.6288 9.94197 12.3869 8.70121 12.7538C7.46046 13.1206 6.13435 13.0766 4.92068 12.6282C3.70701 12.1798 2.67079 11.3511 1.96658 10.2657C1.26236 9.18025 0.927875 7.89627 1.01301 6.60522C1.09814 5.31417 1.59832 4.08523 2.43897 3.10168C3.27961 2.11813 4.41567 1.43266 5.67771 1.14752C6.93975 0.86238 8.26016 0.992836 9.442 1.51943"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M13.0033 1.85742L7.06928 7.85742L5.28906 6.05922"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </span>
                          ANMCH GAYA
                        </li>
                        <li>
                          <span class="icon">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 6.45143V7.00343C12.9993 8.29729 12.5803 9.55624 11.8056 10.5925C11.0309 11.6288 9.94197 12.3869 8.70121 12.7538C7.46046 13.1206 6.13435 13.0766 4.92068 12.6282C3.70701 12.1798 2.67079 11.3511 1.96658 10.2657C1.26236 9.18025 0.927875 7.89627 1.01301 6.60522C1.09814 5.31417 1.59832 4.08523 2.43897 3.10168C3.27961 2.11813 4.41567 1.43266 5.67771 1.14752C6.93975 0.86238 8.26016 0.992836 9.442 1.51943"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M13.0033 1.85742L7.06928 7.85742L5.28906 6.05922"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </span>
                          Truma Surgeon
                        </li>
                        <li>
                          <span class="icon">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 6.45143V7.00343C12.9993 8.29729 12.5803 9.55624 11.8056 10.5925C11.0309 11.6288 9.94197 12.3869 8.70121 12.7538C7.46046 13.1206 6.13435 13.0766 4.92068 12.6282C3.70701 12.1798 2.67079 11.3511 1.96658 10.2657C1.26236 9.18025 0.927875 7.89627 1.01301 6.60522C1.09814 5.31417 1.59832 4.08523 2.43897 3.10168C3.27961 2.11813 4.41567 1.43266 5.67771 1.14752C6.93975 0.86238 8.26016 0.992836 9.442 1.51943"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M13.0033 1.85742L7.06928 7.85742L5.28906 6.05922"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </span>
                          Arthoplasty Surgeon
                        </li>
                        <li>
                          <span class="icon">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 6.45143V7.00343C12.9993 8.29729 12.5803 9.55624 11.8056 10.5925C11.0309 11.6288 9.94197 12.3869 8.70121 12.7538C7.46046 13.1206 6.13435 13.0766 4.92068 12.6282C3.70701 12.1798 2.67079 11.3511 1.96658 10.2657C1.26236 9.18025 0.927875 7.89627 1.01301 6.60522C1.09814 5.31417 1.59832 4.08523 2.43897 3.10168C3.27961 2.11813 4.41567 1.43266 5.67771 1.14752C6.93975 0.86238 8.26016 0.992836 9.442 1.51943"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M13.0033 1.85742L7.06928 7.85742L5.28906 6.05922"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </span>
                          Arthoscopic Surgeon
                        </li>
                        <li>
                          <span class="icon">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 6.45143V7.00343C12.9993 8.29729 12.5803 9.55624 11.8056 10.5925C11.0309 11.6288 9.94197 12.3869 8.70121 12.7538C7.46046 13.1206 6.13435 13.0766 4.92068 12.6282C3.70701 12.1798 2.67079 11.3511 1.96658 10.2657C1.26236 9.18025 0.927875 7.89627 1.01301 6.60522C1.09814 5.31417 1.59832 4.08523 2.43897 3.10168C3.27961 2.11813 4.41567 1.43266 5.67771 1.14752C6.93975 0.86238 8.26016 0.992836 9.442 1.51943"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M13.0033 1.85742L7.06928 7.85742L5.28906 6.05922"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </span>
                          Ilizarov Specialist
                        </li>
                      </ul>
                    )}
                  </div>
                  <div class="doc-tab" id="qualification-tab">
                    {activeSection === "qualification" && (
                      <ul>
                        <li>
                          <span class="icon">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 6.45143V7.00343C12.9993 8.29729 12.5803 9.55624 11.8056 10.5925C11.0309 11.6288 9.94197 12.3869 8.70121 12.7538C7.46046 13.1206 6.13435 13.0766 4.92068 12.6282C3.70701 12.1798 2.67079 11.3511 1.96658 10.2657C1.26236 9.18025 0.927875 7.89627 1.01301 6.60522C1.09814 5.31417 1.59832 4.08523 2.43897 3.10168C3.27961 2.11813 4.41567 1.43266 5.67771 1.14752C6.93975 0.86238 8.26016 0.992836 9.442 1.51943"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M13.0033 1.85742L7.06928 7.85742L5.28906 6.05922"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </span>
                          MBBS
                        </li>
                        <li>
                          <span class="icon">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 6.45143V7.00343C12.9993 8.29729 12.5803 9.55624 11.8056 10.5925C11.0309 11.6288 9.94197 12.3869 8.70121 12.7538C7.46046 13.1206 6.13435 13.0766 4.92068 12.6282C3.70701 12.1798 2.67079 11.3511 1.96658 10.2657C1.26236 9.18025 0.927875 7.89627 1.01301 6.60522C1.09814 5.31417 1.59832 4.08523 2.43897 3.10168C3.27961 2.11813 4.41567 1.43266 5.67771 1.14752C6.93975 0.86238 8.26016 0.992836 9.442 1.51943"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M13.0033 1.85742L7.06928 7.85742L5.28906 6.05922"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </span>
                          MS(Ortho)
                        </li>
                        <li>
                          <span class="icon">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 6.45143V7.00343C12.9993 8.29729 12.5803 9.55624 11.8056 10.5925C11.0309 11.6288 9.94197 12.3869 8.70121 12.7538C7.46046 13.1206 6.13435 13.0766 4.92068 12.6282C3.70701 12.1798 2.67079 11.3511 1.96658 10.2657C1.26236 9.18025 0.927875 7.89627 1.01301 6.60522C1.09814 5.31417 1.59832 4.08523 2.43897 3.10168C3.27961 2.11813 4.41567 1.43266 5.67771 1.14752C6.93975 0.86238 8.26016 0.992836 9.442 1.51943"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M13.0033 1.85742L7.06928 7.85742L5.28906 6.05922"
                                stroke="#858585"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </span>
                          FIMS(Sports)
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Departments */}
      <div className="department_section padding-tb">
        <div className="element_one">
          <div className="elementor-background-overlay"></div>
          <div className="section-header">
            <h2>
              <span>Our</span>
            </h2>
            <h2>Departments</h2>
          </div>
        </div>
        <div className="container">
          <Swiper
            spaceBetween={50}
            slidesPerView={4}
            autoplay={{
              delay: 2500,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            onSlideChange={() => console.log("slide change")}
            modules={[Autoplay, Pagination, Navigation]}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {departments.map((depar) => {
              return (
                <SwiperSlide>
                  <img src={depar.img} alt="" />
                  <div className="dert-content">
                    <h4 className="deparment_title">{depar.name}</h4>
                    <Link to="#" className="lab-btn">
                      <span>
                        Read More{" "}
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </Link>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      {/* faqs */}
      <div className="faq_wrapper padding-tb">
        <div
          className="section-header wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
          data-wow-delay=".1s"
        >
          <h2>Frequently Asked Questions</h2>
        </div>

        <div className="accordion">
          {faqs.map((faq, i) => {
            return (
              <div className="item">
                <div className="title" onClick={() => toogle(i)}>
                  <h4>{faq.question}</h4>
                  <strong>{selected === i ? "-" : "+"}</strong>
                </div>
                <div className={selected === i ? "content show" : "content"}>
                  {faq.answer}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* <!-- ==========Newsletter Section Ends Here========== --> */}
      <section className="newsletter-section style-1">
        <div className="container">
          <div className="section-wrapper">
            <div
              className="left wow animate__animated animate__fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay=".1s"
            >
              {/* <img src={stethoscope} alt="" /> */}
              <div className="news-title">
                <h2>
                  Emergency
                  <i className="fa fa-ambulance" aria-hidden="true"></i>
                </h2>
                <p>
                  <Link to="#">
                    <i
                      className="fa fa-phone"
                      aria-hidden="true"
                      style={{ marginRight: "10px" }}
                    ></i>
                    +91-9006982665
                  </Link>
                </p>
              </div>
              {/* <img src={firstAid} alt="" /> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==========Newsletter Section Ends Here========== --> */}
    </>
  );
};

export default DepartmentDetail;
