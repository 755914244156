import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination } from "swiper/modules";
import infrastructure from "../assets/images/model/infrastructure.jpg";

const Milestone = () => {
  return (
    <>
      <section className="milestone_section">
        <div className="page-header-content">
          <div className="container">
            <div className="page-header-content-inner">
              <div
                className="page-title"
                style={{ textTransform: "capitalize" }}
              >
                <h2>Milestone</h2>
              </div>
              <ol className="breadcrumb">
                <li>
                  <a href="index.jsx">Home -</a>
                </li>
                <li className="active">Milestone</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Milestone;
