import React, { useState } from "react";
import "../assets/styles/Gallery.css";
import g1 from "../assets/images/gallery/g1.jpg";
import g2 from "../assets/images/gallery/g2.jpg";
import g3 from "../assets/images/gallery/g3.jpg";
import g4 from "../assets/images/gallery/g4.jpg";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";

const Gallery = () => {
  const [sliderNumber, setSliderNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const galleryImages = [
    {
      img: g1,
    },
    {
      img: g2,
    },
    {
      img: g3,
    },
    {
      img: g4,
    },
    {
      img: g1,
    },
    {
      img: g2,
    },
    {
      img: g3,
    },
    {
      img: g4,
    },
  ];

  const handleOpenModal = (data) => {
    setSliderNumber(data);
    setOpenModal(true);
  };

  const handleClosemodal = () => {
    setOpenModal(false);
  };

  const prevSlide = () => {
    sliderNumber === 0
      ? setSliderNumber(galleryImages.length - 1)
      : setSliderNumber(sliderNumber - 1);
  };

  const nextSlide = () => {
    sliderNumber + 1 === galleryImages.length
      ? setSliderNumber(0)
      : setSliderNumber(sliderNumber + 1);
  };

  return (
    <>
      <section className="gallery_section_page">
        <div className="page-header-content">
          <div className="container">
            <div className="page-header-content-inner">
              <div
                className="page-title"
                style={{ textTransform: "capitalize" }}
              >
                <h2>Gallery</h2>
              </div>
              <ol className="breadcrumb">
                <li>
                  <a href="index.jsx">Home -</a>
                </li>
                <li className="active">Gallery</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      {/* gallery */}

      <section className="galllery_md padding-tb">
        <div className="container">
          {openModal && (
            <div className="sliderWrap">
              <FaChevronCircleLeft
                className="btnPrev"
                onClick={prevSlide}
                size="40px"
              />

              <FaChevronCircleRight
                className="btnNext"
                onClick={nextSlide}
                size="40px"
              />
              <IoMdCloseCircle
                className="btnClose"
                onClick={handleClosemodal}
                size="40px"
              />

              <div className="fullScreenImage">
                <img src={galleryImages[sliderNumber].img} alt="" srcset="" />
              </div>
            </div>
          )}

          <div className="galleryWrap">
            {galleryImages &&
              galleryImages.map((slide, index) => {
                return (
                  <div
                    className="single"
                    key={index}
                    onClick={() => handleOpenModal(index)}
                  >
                    <img src={slide.img} alt="" />
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
