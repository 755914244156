import React, { useState } from "react";
import "../assets/styles/Career.css";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { LiaUserNurseSolid } from "react-icons/lia";
import { FaUserDoctor } from "react-icons/fa6";
import { IoMdCloseCircleOutline } from "react-icons/io";

const Career = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <>
      <section className="career_Sec">
        <div className="page-header-content">
          <div className="container">
            <div className="page-header-content-inner">
              <div
                className="page-title"
                style={{ textTransform: "capitalize" }}
              >
                <h2>Career</h2>
              </div>
              <ol className="breadcrumb">
                <li>
                  <a href="index.jsx" style={{ color: "#fff" }}>
                    Home -
                  </a>
                </li>
                <li className="active">Career</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section class="vacancy_sec">
        <div className="topRoundBg">
          <h2>Being a part of "Advance Orthopaedic And Maternity Center"</h2>
        </div>
        <div className="desc">
          <p>
            We are looking for individuals who shall help us further the vision
            of our founder we have cherished every day of our existence.
          </p>
          <p>
            Individuals who are passionate about what they do, do it selflessly
            and are proud of the difference it makes in the lives of others.
            Individuals who let their work speak for them. Individuals for whom
            the pursuit of excellence never ends.
          </p>
        </div>
        <div className="job_desc">
          <div className="medical">
            <div className="vac_card">
              <div className="cate_title">
                <h3>Medical</h3>
              </div>
              <div className="vac_desc_sec">
                <ul className="vac_list">
                  <li class="careersList">
                    <h3 class="sel">Clinical Assistant/ Associate- Urology</h3>
                    <p>
                      Opportunity for the post of Clinical
                      Assistant/Associate-Urology for our Hospital in Mahim,
                      Mumbai. Location: Mahim, Mumbai Qualification &amp;
                      Experience: MBBS +&nbsp; MCH (Urology).Freshers can also
                      apply.
                    </p>
                  </li>
                  <li class="careersList">
                    <h3>Anaesthesia- Clinical Assistant/ Associate</h3>
                    <p>
                      Opportunity for Anaesthesia- Registrar/Clinical Assistant/
                      Associate for our Hospital in Mahim Location: Mahim
                      Experience: Freshers can also apply Qualification:
                      Candidate should be MD/DNB Anaesthesia. DA with minimum 1
                      year experience can apply.
                    </p>
                  </li>
                  <li class="careersList">
                    <h3>House Officer in Cardiology</h3>
                    <p>
                      {" "}
                      Opportunity for House Officer in Cardiology Department for
                      our hospital in Mahim.&nbsp; Experience: Fresher can also
                      apply&nbsp; Qualification:MBBS &nbsp; If interested,
                      Request you to share your resume on aomc@gmail.com
                    </p>
                  </li>
                  <li class="careersList">
                    <h3>
                      Critical Care- Registrar/Clinical Assistant/Associate
                    </h3>
                    <p>
                      Opportunity for Critical Care- Registrar/Clinical
                      Assistant/Associate/Sr.Clinical Associate&nbsp;for our
                      Hospital&nbsp;&nbsp;in Mahim &nbsp; Qualification and
                      Experience: &nbsp; 1) MBBS+MD/ DNB in Medicine/
                      Respiratory medicine/ Pulmonology/ Anaesthesia/ Emergency
                      Medicine/IDCCM &nbsp; 2) MBBS+Diploma.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="non-medical">
            <div className="vac_card">
              <div className="cate_title">
                <h3>Non-Medical</h3>
              </div>
              <div className="vac_desc_sec">
                <ul className="vac_list">
                  <li class="careersList">
                    <h3>Executive - Nurse</h3>
                    <p>
                      Opportunity for Executive - Nurse for our hospital in
                      Mahim Location: Mahim - Mumbai Qualification: BSc Nursing
                      + MSc Nursing / MHA / MBA Positions.
                    </p>
                  </li>
                  <li class="careersList">
                    <h3>Respiratory/ICU Technician</h3>
                    <p>
                      Opportunity for Respiratory/ICU Technician on yearly fixed
                      term contract on Hospital Payroll for our hospital in
                      Mahim Location: Mahim Experience: Minimum 01 year
                      experience Qualification: BSC If interested, kindly share
                      your CV.
                    </p>
                  </li>
                  <li class="careersList">
                    <h3>Telephone Operator</h3>
                    <p>
                      Opportunity for a 'Telephone Operator'&nbsp; for our
                      hospital based in Mumbai (Mahim) on yearly fixed term
                      contract(renewable) &nbsp; Experience: Minimum 1 year in
                      EPBAX System.
                    </p>
                  </li>
                  <li class="careersList">
                    <h3>Officer/ Executive Marketing</h3>
                    <p>
                      Opportunity for Officer/Executive Marketing for our
                      Hospital in Mahim. Location: Mahim Qualification: MBA
                      Preferred Experience: Minimum 2+ years in Hospital or
                      Pharma industry To generate business from
                      GPs/Physicians/Nursing homes .
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="apply_contact_sec">
          <div className="apply_sec_">
            <div className="apply_left"></div>
            <div className="apply_right">
              <div class="motopsubbtn">
                <p>
                  <strong>
                    Looking to join our Parivar but can't find a job opening
                    that matches your expertise? You can also apply here and if
                    a relevant job requirement comes up, we will review your
                    application and get back to you.
                  </strong>
                </p>
                <a
                  className="lab-btn"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "10px",
                    marginTop: "10px",
                    gap: "5px",
                  }}
                >
                  <span>Submit Your CV</span>
                  <IoIosArrowDroprightCircle fontSize="20px" />
                </a>{" "}
              </div>
            </div>
          </div>
        </div> */}

        <div class="doc-email-container">
          <div class="doc-email-inner-conatiner right-border">
            <span>
              We provide a solid foundation to help you build an enriching
              professional career in the healthcare industry.
            </span>
            <span>
              If you wish to join our talent pool, email us your resumes with
              job roles that you are interested in:
            </span>
            <a
              className="lab-btn"
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "10px",
                marginTop: "10px",
                gap: "5px",
              }}
              onClick={() => setShowModal(true)}
            >
              <span>Submit Your CV</span>
              <IoIosArrowDroprightCircle fontSize="20px" />
            </a>{" "}
          </div>
          <div class="doc-email-inner-conatiner">
            <ul>
              <li class="doctor">
                <strong>Doctor</strong>
                <span>-</span>
                <a
                  href="mailto:aomc@gmail.com

"
                >
                  aomc@gmail.com
                </a>
              </li>
              <li class="nurse">
                <strong>Nurses</strong>
                <span>-</span>
                <a
                  href="mailto:aomc@gmail.com

"
                >
                  aomc@gmail.com
                </a>
              </li>
              <li class="unmedical">
                <strong>Non Medical</strong>
                <span>-</span>
                <a
                  href="mailto:aomc@gmail.com

"
                >
                  aomc@gmail.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {showModal && (
        <section className="modal_wrapper">
          <div className="modal_container">
            <div className="modal_title">
              <h2>Apply here</h2>
            </div>

            <button className="close_btn" onClick={() => setShowModal(false)}>
              <IoMdCloseCircleOutline fontSize="25px" />
            </button>
            <div className="here-form">
              <form>
                <div className="my_modal">
                  <div className="modal-field ">
                    <div className="modal_card">
                      <label htmlFor="">Enter Your Name</label>
                      <input type="text" id="fname" name="name" />
                      <label htmlFor="">
                        {" "}
                        How did you come to know about the vacancy?
                      </label>
                      <select id="country" name="country">
                        <option value="2">Hospital website</option>

                        <option value="1">Friends</option>
                        <option value="3">Noukri.com</option>
                        <option value="3">Newspaper</option>
                        <option value="3">Linkedin</option>
                      </select>

                      <label htmlFor="">
                        {" "}
                        What position are you applying for?
                      </label>
                      <input type="text" id="position" name="position" />
                    </div>
                  </div>
                  <div className="modal_desc">
                    <div className="modal_card">
                      <label>Upload CV</label>
                      <input
                        type="file"
                        accept=".pdf,.doc,.docx"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                </div>
                <button class="lab-btn" type="submit">
                  <span>
                    Appointment Now <i class="icofont-double-right"></i>
                  </span>
                </button>
              </form>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Career;
