import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo/AOMC.png";
import contact from "../assets/images/header/contact.png";
import mail from "../assets/images/header/mail.png";
import location from "../assets/images/header/location.png";
import ortho from "../assets/images/departments/ortho.jpg";
import { FaPlus } from "react-icons/fa6";
import { FaMinus, FaTimes } from "react-icons/fa";
import "../assets/styles/main.css";
import "../assets/styles/responsive.css";
import "../assets/styles/global.css";
import axios from "axios";

const Navbar = () => {
  const [contactInfo, setContactInfo] = useState(false);
  const [dropdownmenu, setDropdownMenu] = useState(null);
  const [activeImage, setActiveImage] = useState(ortho);
  const [data, setData] = useState({});
  const [isMenuVisible, setMenuVisible] = useState(true);
  const [isHovered, setHovered] = useState(false);

  const handleLinkClick = () => {
    setContactInfo(false);
    setMenuVisible(false);
    // setMenuVisible(false);
  };

  const handleMouseEnter = () => {
    setMenuVisible(true);
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const toggleDropdown = (i) => {
    if (dropdownmenu === i) {
      return setDropdownMenu(null);
    }
    setDropdownMenu(i);
  };

  const changeImage = (img) => {
    setActiveImage(img);
  };

  useEffect(() => {
    let baseurl = process.env.REACT_APP_API_URL;
    axios
      .get(baseurl + `/v1/category`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <section className="navbar">
      <div className="top__navbar">
        <div className="container">
          <div className="row">
            <div className="top__navbar__section">
              <div className="header__logo">
                <Link to="/">
                  <img src={logo} alt="" width={"100px"} />
                </Link>
              </div>
              <ul className="header-contact-info">
                <li className="item">
                  <div className="item-inner">
                    <div className="item-thumb">
                      <img src={contact} alt="" srcSet="" />
                    </div>
                    <div className="item-content">
                      <span>Number :</span>
                      <p>+91-9006982665</p>
                    </div>
                  </div>
                </li>
                <li className="item">
                  <div className="item-inner">
                    <div className="item-thumb">
                      <img src={mail} alt="" />
                    </div>
                    <div className="item-content">
                      <span>Email :</span>
                      <p>aomc@gmail.com</p>
                    </div>
                  </div>
                </li>
                <li className="item">
                  <div className="item-inner">
                    <div className="item-thumb">
                      <img src={location} alt="" />
                    </div>
                    <div className="item-content">
                      <span>Address :</span>
                      <p>Patna, Bihar</p>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="humberger__menu">
                <Link onClick={() => setContactInfo(!contactInfo)}>
                  {!contactInfo ? (
                    <div>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        height="2em"
                        width="3em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z"></path>
                      </svg>
                    </div>
                  ) : (
                    <div>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        height="2em"
                        width="3em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M331.3 308.7L278.6 256l52.7-52.7c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-52.7-52.7c-6.2-6.2-15.6-7.1-22.6 0-7.1 7.1-6 16.6 0 22.6l52.7 52.7-52.7 52.7c-6.7 6.7-6.4 16.3 0 22.6 6.4 6.4 16.4 6.2 22.6 0l52.7-52.7 52.7 52.7c6.2 6.2 16.4 6.2 22.6 0 6.3-6.2 6.3-16.4 0-22.6z"></path>
                        <path d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"></path>
                      </svg>
                    </div>
                  )}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom__header">
        {!contactInfo ? (
          <div className="main_nav">
            <div className="container">
              <div className="row">
                <nav className="main-menu">
                  <ul className="menu-link">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link
                        to="/about"
                        className="submenu"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        About Us
                        <FaPlus className="plus" />
                        <FaMinus className="minus" />
                      </Link>
                      {isMenuVisible && (
                        <ul className="submenu__list">
                          <li>
                            <Link to="/about" onClick={handleLinkClick}>
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link to="/overview" onClick={handleLinkClick}>
                              Overview
                            </Link>
                          </li>
                          <li>
                            <Link to="/director-desk" onClick={handleLinkClick}>
                              Director Desk
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/mission-vision"
                              onClick={handleLinkClick}
                            >
                              Mission & Vision
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/achievement-certificate"
                              onClick={handleLinkClick}
                            >
                              Achievement & Certificate
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="submenu"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        Departments
                        <FaPlus className="plus" />
                        <FaMinus className="minus" />
                      </Link>
                      {isMenuVisible && (
                        <div className="mega-box">
                          <div className="content">
                            <div className="row">
                              <div className="col-lg-3">
                                <img
                                  src={activeImage}
                                  alt=""
                                  id="slider"
                                  style={{ height: "200px", width: "300px" }}
                                />
                              </div>
                              <div className="col-lg-3">
                                <ul className="mega-box-submenu">
                                  {data?.data?.map((row, index) => {
                                    return (
                                      <li
                                        onMouseEnter={() =>
                                          changeImage(row.image1)
                                        }
                                        key={index}
                                      >
                                        <Link
                                          to={`/department/${row.slug}`}
                                          onClick={handleLinkClick}
                                        >
                                          {row.name}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="submenu"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        Gallery <FaPlus className="plus" />
                        <FaMinus className="minus" />
                      </Link>
                      {isMenuVisible && (
                        <ul className="submenu__list">
                          <li>
                            <Link
                              to="/gallery/hospital"
                              onClick={handleLinkClick}
                            >
                              Hospital
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/gallery/hospital-staff"
                              onClick={handleLinkClick}
                            >
                              Hospital Staff{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/gallery/event" onClick={handleLinkClick}>
                              Event
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/gallery/free-camp"
                              onClick={handleLinkClick}
                            >
                              Free Camp
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/gallery/press-release"
                              onClick={handleLinkClick}
                            >
                              Press Release
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li onClick={handleLinkClick}>
                      <Link to="/career">Career</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        ) : (
          <div className="mobile-menu-link">
            <div className="close-menu">
              <FaTimes onClick={() => setContactInfo(!contactInfo)} />
            </div>
            <ul>
              <li onClick={handleLinkClick}>
                <Link to="/">Home</Link>
              </li>

              <li
                className="resp-submenu"
                onClick={() => toggleDropdown("about")}
              >
                <Link to="#" className="submenu">
                  About Us
                </Link>
                <span>{dropdownmenu === "about" ? "-" : "+"}</span>
              </li>
              <ul
                className={
                  dropdownmenu === "about"
                    ? "submenu_list show"
                    : "submenu_list"
                }
              >
                <li onClick={handleLinkClick}>
                  <Link to="/about">
                    About Advance Orthopaedic & Maternity Centre
                  </Link>
                </li>
                <li onClick={handleLinkClick}>
                  <Link to="/overview">Overview</Link>
                </li>
                <li onClick={handleLinkClick}>
                  <Link to="/director-desk">Director Desk</Link>
                </li>
                <li onClick={handleLinkClick}>
                  <Link to="/mission-vision">Mission & Vision</Link>
                </li>
                <li onClick={handleLinkClick}>
                  <Link to="/achievement-certificate">
                    Achievement & Certificate
                  </Link>
                </li>
              </ul>

              <li
                className="resp-submenu"
                onClick={() => toggleDropdown("department")}
              >
                <Link className="submenu">Departments</Link>
                <span>{dropdownmenu === "department" ? "-" : "+"}</span>
              </li>
              <ul
                className={
                  dropdownmenu === "department"
                    ? "submenu_list show"
                    : "submenu_list"
                }
              >
                <li onClick={handleLinkClick}>
                  {" "}
                  <Link to="/department/orthopedic">Orthopaedic</Link>
                </li>
                <li onClick={handleLinkClick}>
                  {" "}
                  <Link to="/department/obstetrics">Obstetrics</Link>{" "}
                </li>
                <li onClick={handleLinkClick}>
                  {" "}
                  <Link to="/department/gynaecology">Gynaecology</Link>
                </li>
                <li onClick={handleLinkClick}>
                  {" "}
                  <Link to="/department/general-surgery">General surgery</Link>
                </li>
                <li onClick={handleLinkClick}>
                  {" "}
                  <Link to="/department/regenerative-medicine">
                    Regenerative Medicine
                  </Link>{" "}
                </li>
                <li onClick={handleLinkClick}>
                  {" "}
                  <Link to="/department/anaeasthesiology">
                    Anaeasthesiology
                  </Link>
                </li>
                <li onClick={handleLinkClick}>
                  {" "}
                  <Link to="/department/physiotherapy">Physiotherapy</Link>
                </li>
                <li onClick={handleLinkClick}>
                  {" "}
                  <Link
                    to="/department/dental"
                    onmouseover="changeImg('assets/images/departments/dental.jpg')"
                  >
                    Dental
                  </Link>
                </li>
              </ul>
              {/* <li>
                <Link to="/team">Doctor Team</Link>
              </li> */}
              <li
                className="resp-submenu"
                onClick={() => toggleDropdown("gallery")}
              >
                <Link to="/gallery">Gallery</Link>
                <span>{dropdownmenu === "gallery" ? "-" : "+"}</span>
              </li>
              <ul
                className={
                  dropdownmenu === "gallery"
                    ? "submenu_list show"
                    : "submenu_list"
                }
              >
                <li onClick={handleLinkClick}>
                  <Link to="/gallery/hospital">Hospital</Link>
                </li>
                <li onClick={handleLinkClick}>
                  <Link to="/gallery/hospital-staff">Hospital Staff </Link>
                </li>
                <li onClick={handleLinkClick}>
                  <Link to="/gallery/event">Event</Link>
                </li>
                <li onClick={handleLinkClick}>
                  <Link to="/gallery/free-camp">Free Camp</Link>
                </li>
                <li onClick={handleLinkClick}>
                  <Link to="/gallery/press-release">Press Release</Link>
                </li>
              </ul>
              <li onClick={handleLinkClick}>
                <Link to="/career">Career</Link>
              </li>
              <li onClick={handleLinkClick}>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export default Navbar;
