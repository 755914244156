import React from "react";
import { useParams } from "react-router-dom";
import "../assets/styles/doctordetail.css";
import dr from "../assets/images/team/dr.png";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import a1 from "../assets/images/recognition/a1.png";
import a2 from "../assets/images/recognition/a2.png";
import a3 from "../assets/images/recognition/a3.png";

const DoctorDetail = () => {
  const { data } = useParams();

  return (
    <>
      <section className="doctor-header-section">
        <div className="page-header-content">
          <div className="container">
            <div className="treatment_banner_wrapper">
              <div className="page-header-content-inner">
                <div
                  className="page-title"
                  style={{ textTransform: "capitalize" }}
                >
                  <h2>{data}</h2>
                </div>
                <a href="#" className="lab-btn animation lg_size_btn">
                  <span>
                    Enquiry Now
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="doctor_details padding-tb">
        <div className="container">
          <div className="detail_wrapper">
            <div className="doctor_content">
              <div className="doctor_thumb">
                <img src={dr} alt="" />
              </div>
              <div className="doctor_desc">
                <div class="section-heading">
                  <h3>DR P.K.JHA</h3>
                  <span class="disi">Chief of Medicine</span>
                  <p>
                    Procedur arrain manu producs rather convenet cuvate mantana
                    this manufcur produc rather conven cuvatie mantan this
                    conven cuvate multscplinari testin motin was procedur
                    arramin
                  </p>
                  <h4>Personal Statement</h4>
                  <p>
                    Dramatica engae visionary quality vectors throuh top line
                    are oportues Assertive transfor market-driven converence
                    visextense inteectua capt Intrinsicly drive premier proceses
                    and traarent metrcs rapdiousl levrae client-based channels
                    via distinctive leadership skills.
                  </p>
                </div>
                <div class="about-content">
                  <ul>
                    <li>
                      <div class="name">
                        <p>Adress</p>
                      </div>
                      <div class="info">
                        <p>Patna</p>
                      </div>
                    </li>
                    <li>
                      <div class="name">
                        <p>Email</p>
                      </div>
                      <div class="info">
                        <p>aomc@gmail.com</p>
                      </div>
                    </li>
                    <li>
                      <div class="name">
                        <p>Phone</p>
                      </div>
                      <div class="info">
                        <p>+258 369 147</p>
                      </div>
                    </li>
                    <li>
                      <div class="name">
                        <p>Website</p>
                      </div>
                      <div class="info">
                        <p>https://www.advanceorthopaedic.in/</p>
                      </div>
                    </li>
                    <li>
                      <div class="name">
                        <p>Interview</p>
                      </div>
                      <div class="info">
                        <p>Read The Interview</p>
                      </div>
                    </li>
                    <li>
                      <div class="name">
                        <p>Follow us</p>
                      </div>
                      <div class="info">
                        <p>
                          <FaTwitterSquare />
                          <FaInstagramSquare />
                          <FaFacebookSquare />
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="sp-personal-content">
              <div class="personal_achievement">
                <div class="progress_section">
                  <div class="skill-items">
                    <h5>Personal Skill</h5>
                    <div class="skill-bar">
                      <div class="skill-item">
                        <div
                          class="pie"
                          data-pie='{ "index": 3, "percent": 96, "colorSlice": "#FF4600", "colorCircle": "#f1f1f1", "fontWeight": 700, "stroke": 7, "size": 90,
                                        "fontSize": "1.125rem","fontColor": "#FF4600" }'
                        >
                          <svg width="90" height="90" viewBox="0 0 100 100">
                            <circle
                              cx="50"
                              cy="50"
                              r="42"
                              shape-rendering="geometricPrecision"
                              data-angle=""
                              fill="none"
                              stroke="#f1f1f1"
                              stroke-width="7"
                            ></circle>
                            <circle
                              cx="50"
                              cy="50"
                              r="42"
                              shape-rendering="geometricPrecision"
                              data-angle="0"
                              class="pie-circle-3"
                              fill="none"
                              transform="rotate(-90, 50, 50)"
                              stroke-width="7"
                              stroke-linecap=""
                              stroke="#FF4600"
                              stroke-dasharray="253.44, 20000"
                              data-angel="96"
                            ></circle>
                            <text
                              class="pie-percent-3"
                              x="50%"
                              y="50%"
                              font-size="1.125rem"
                              font-weight="700"
                              fill="#FF4600"
                              text-anchor="middle"
                              dominant-baseline="central"
                            >
                              96%
                            </text>
                          </svg>
                        </div>
                        <p>Resilience</p>
                      </div>
                      <div class="skill-item">
                        <div
                          class="pie"
                          data-pie='{ "index": 6, "percent": 95, "colorSlice": "#1ABC80", "colorCircle": "#f1f1f1", "fontWeight": 700, "stroke": 7, "size": 90,
                                        "fontSize": "1.125rem","fontColor": "#1ABC80" }'
                        >
                          <svg width="90" height="90" viewBox="0 0 100 100">
                            <circle
                              cx="50"
                              cy="50"
                              r="42"
                              shape-rendering="geometricPrecision"
                              data-angle=""
                              fill="none"
                              stroke="#f1f1f1"
                              stroke-width="7"
                            ></circle>
                            <circle
                              cx="50"
                              cy="50"
                              r="42"
                              shape-rendering="geometricPrecision"
                              data-angle="0"
                              class="pie-circle-6"
                              fill="none"
                              transform="rotate(-90, 50, 50)"
                              stroke-width="7"
                              stroke-linecap=""
                              stroke="#1ABC80"
                              stroke-dasharray="250.8, 20000"
                              data-angel="95"
                            ></circle>
                            <text
                              class="pie-percent-6"
                              x="50%"
                              y="50%"
                              font-size="1.125rem"
                              font-weight="700"
                              fill="#1ABC80"
                              text-anchor="middle"
                              dominant-baseline="central"
                            >
                              95%
                            </text>
                          </svg>
                        </div>
                        <p>Ethics</p>
                      </div>
                      <div class="skill-item">
                        <div
                          class="pie"
                          data-pie='{ "index": 4, "percent": 80, "colorSlice": "#1BA1D2", "colorCircle": "#f1f1f1", "fontWeight": 700, "stroke": 7, "size": 90,
                                        "fontSize": "1.125rem","fontColor": "#1BA1D2" }'
                        >
                          <svg width="90" height="90" viewBox="0 0 100 100">
                            <circle
                              cx="50"
                              cy="50"
                              r="42"
                              shape-rendering="geometricPrecision"
                              data-angle=""
                              fill="none"
                              stroke="#f1f1f1"
                              stroke-width="7"
                            ></circle>
                            <circle
                              cx="50"
                              cy="50"
                              r="42"
                              shape-rendering="geometricPrecision"
                              data-angle="0"
                              class="pie-circle-4"
                              fill="none"
                              transform="rotate(-90, 50, 50)"
                              stroke-width="7"
                              stroke-linecap=""
                              stroke="#1BA1D2"
                              stroke-dasharray="211.20000000000002, 20000"
                              data-angel="80"
                            ></circle>
                            <text
                              class="pie-percent-4"
                              x="50%"
                              y="50%"
                              font-size="1.125rem"
                              font-weight="700"
                              fill="#1BA1D2"
                              text-anchor="middle"
                              dominant-baseline="central"
                            >
                              80%
                            </text>
                          </svg>
                        </div>
                        <p>Compassion</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="awards_section">
                  <div class="award-items">
                    <h5>Recognitions Award</h5>
                    <div class="award-item">
                      <div class="award-inner text-center">
                        <div class="award-img">
                          <img src={a1} alt="Award 2013" />
                        </div>
                        <p>Award 2013</p>
                      </div>
                      <div class="award-inner text-center">
                        <div class="award-img">
                          <img src={a2} alt="Award 2015" />
                        </div>
                        <p>Award 2015</p>
                      </div>
                      <div class="award-inner text-center">
                        <div class="award-img">
                          <img src={a3} alt="Award 2021" />
                        </div>
                        <p>Award 2021</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DoctorDetail;
