import { Link } from "react-router-dom";
import "../assets/styles/main.css";
import "../assets/styles/responsive.css";
import { useEffect, useState } from "react";
import axios from "axios";

const Footer = () => {
  const [dropdownmenu, setDropdownMenu] = useState(null);
  const [data, setData] = useState({});
  const [firstHalf, setFirstHalf] = useState([]);
  const [secondHalf, setSecondHalf] = useState([]);
  const [isHovered, setHovered] = useState(false);

  const toggleDropdown = (i) => {
    if (dropdownmenu === i) {
      return setDropdownMenu(null);
    }
    setDropdownMenu(i);
  };

  useEffect(() => {
    let baseurl = process.env.REACT_APP_API_URL;
    axios
      .get(baseurl + `/v1/category`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    console.log(data);

    const half = Math.ceil(data?.data?.length / 2);
    setFirstHalf(data?.data?.slice(0, half));
    setSecondHalf(data?.data?.slice(half));
  }, [data]);

  return (
    <>
      <section className="footer-section style-1">
        <div className="container">
          <div className="section-wrapper">
            <div className="footer-top">
              <div className="row">
                <div
                  className="footer_card animation"
                  data-wow-duration="1s"
                  data-wow-delay=".1s"
                  data-aos="fade-up"
                >
                  <div className="contact-info">
                    <h3>Contact Info</h3>
                    <p className="roboto-thin">
                      Rapidiously seize wireless strategic theme areas and
                      corporate testing procedures. Uniquely
                    </p>
                    <ul className="lab-ul">
                      <li>
                        <span style={{ marginRight: "5px" }}>
                          <i
                            className="fa fa-map-marker"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span>
                          {" "}
                          Patliputra Station,Infront of Pani Tanki Ashiyna
                          Nagar,Patna
                        </span>
                      </li>
                      <li>
                        <span style={{ marginRight: "5px" }}>
                          {" "}
                          <i
                            className="fa fa-phone"
                            aria-hidden="true"
                          ></i>{" "}
                        </span>
                        <span> +91-9006982665</span>
                      </li>
                      <li>
                        <span style={{ marginRight: "5px" }}>
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                        <span>
                          <span> info@aomc.com</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="footer_card animation"
                  data-wow-duration="1s"
                  data-wow-delay=".2s"
                  data-aos="fade-up"
                >
                  <div className="doctor-info mb-5 mb-sm-0">
                    <h3>Our Doctors</h3>
                    <ul className="lab-ul roboto-thin">
                      <li>
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                        <Link to="#">DR PRAVIN KUMAR JHA</Link>
                      </li>
                      <li>
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                        <Link to="#">DR AMITA BHARTI </Link>
                      </li>
                      <li>
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                        <Link to="#">DR SANJEEV PATHAK</Link>
                      </li>
                      <li>
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                        <Link to="#">DR SHASHANK OJHA</Link>
                      </li>
                      <li>
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                        <Link to="#">DR SHAILENDRA TIWARI</Link>
                      </li>
                      <li>
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                        <Link to="#">DR ABHINASH DUBE</Link>
                      </li>
                      <li>
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                        <Link to="#">DR RAGINI MISHRA</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="footer_card animation"
                  data-wow-duration="1s"
                  data-wow-delay=".3s"
                  data-aos="fade-up"
                >
                  <div className="service-info mb-5 mb-sm-0">
                    <h3>Our Services</h3>

                    <div className="row">
                      <div className="col-lg-6 col-sm-12">
                        <ul className="lab-ul roboto-thin">
                          {firstHalf?.map((row, index) => {
                            return (
                              <li key={index}>
                                <i
                                  className="fa fa-angle-double-right"
                                  aria-hidden="true"
                                ></i>
                                <Link to={`/department/${row.slug}`}>
                                  {row.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <ul className="lab-ul roboto-thin">
                          {secondHalf?.map((row, index) => (
                            <li>
                              <i
                                className="fa fa-angle-double-right"
                                aria-hidden="true"
                              ></i>
                              <Link to={`/department/${row.slug}`}>
                                {row.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div
                className="copyright text-center animation"
                data-aos="fade-up"
              >
                <span>
                  Copyright &copy; 2024 <Link to="/">AOMC</Link>. Designed by
                  <Link to="https://csgroups.in/"> CS Groups</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
