import React from "react";
import "../assets/styles/About.css";
import dr from "../assets/images/team/dr.png";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Director = () => {
  return (
    <>
      <section className="director-desk">
        {/* <div className="page-header-content">
          <div className="container">
            <div className="page-header-content-inner">
              <div className="page-title">
                <h2>Director Desk</h2>
              </div>
              <ol className="breadcrumb">
                <li>
                  <a href="index.jsx">Home -</a>
                </li>
                <li className="active">Director Desk</li>
              </ol>
            </div>
          </div>
        </div> */}
      </section>

      <section className="padding-tb"></section>

      <section className="director_section padding-tb">
        <div className="container">
          <div className="section-header">
            <h2>Director Desk</h2>
          </div>
          <div className="director_wrapper">
            <Swiper
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="director_swiper_section">
                  <div className="direct_thumb">
                    <img
                      src={dr}
                      alt=""
                      style={{ width: "100%", height: "300px" }}
                    />
                  </div>
                  <div className="direct_intro">
                    <h2>P.K.JHA</h2>
                    <p>
                      Director of Advance Othopedic And Maternity Surgeon Patna{" "}
                    </p>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <h3>Overview</h3>

                <p>
                  MBBS, MS(Ortho), FIMS(Sports), EX Senior Resident NMCH Patna,
                  EX ASSitent Professor , ANMCH Gaya, Trauma Surgeon ,
                  Arthoplasty Surgeon, Arthoscopic Surgeon, Ilizarov Specialist,
                  Consultant Orthopedic Surgeon at Advance Othopedic And
                  Maternity Surgeon Patna
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <h3>Lorem, ipsum dolor.</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Corporis omnis quos, itaque voluptatem ea fugiat debitis,
                  voluptas minus dolores illo eum modi quia! Adipisci, debitis
                  temporibus vero excepturi illum doloremque!
                </p>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      <section className="padding-tb"></section>
    </>
  );
};

export default Director;
