import React from "react";
import "../assets/styles/About.css";
import overview from "../assets/images/about/overview.jpg";

const Overview = () => {
  return (
    <>
      <section className="about_section">
        <div className="page-header-content">
          <div className="container">
            <div className="page-header-content-inner">
              <div
                className="page-title"
                style={{ textTransform: "capitalize" }}
              >
                <h2>Overview</h2>
              </div>
              <ol className="breadcrumb">
                <li>
                  <a href="index.jsx">Home -</a>
                </li>
                <li className="active">Overview</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <div className="overview_section_md padding-tb">
        <div className="container">
          {/* <div className="section-header">
            <h2>Overview</h2>
          </div> */}
          <div className="overview_wrapper">
            <div className="overview_left">
              <img src={overview} alt="" style={{ width: "100%" }} />
            </div>
            <div className="overview_right">
              <h3>About Us</h3>
              <p>
                "Welcome to our Advanced Orthopedic and Maternity Center, where
                we combine cutting-edge medical expertise with compassionate
                care to provide unparalleled services in orthopedics and
                maternity care. At our center, we prioritize the health and
                well-being of our patients, offering a comprehensive range of
                specialized treatments and personalized attention to address
                diverse needs. Our orthopedic department is staffed by highly
                skilled surgeons and medical professionals who are dedicated to
                restoring mobility, function, and quality of life for patients
                with orthopedic conditions. From joint replacements to sports
                injuries, we employ advanced surgical techniques and
                state-of-the-art technology to deliver optimal outcomes for
                every individual. In our maternity wing, expectant mothers
                receive exceptional care throughout their pregnancy journey and
                childbirth experience. Our team of obstetricians, nurses, and
                support staff ensures that each mother-to-be receives
                personalized care tailored to her unique needs, promoting a safe
                and comfortable delivery for both mother and baby. Committed to
                excellence in healthcare, we strive to create a supportive
                environment where patients feel empowered, informed, and valued
                every step of the way. Whether you're seeking orthopedic
                treatment or maternity care, you can trust our center to provide
                the highest standard of medical care with compassion and
                integrity."
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
