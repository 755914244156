import React from "react";
import "../assets/styles/About.css";
import s1 from "../assets/images/service/icon/s1.png";
import s2 from "../assets/images/service/icon/s2.png";
import s3 from "../assets/images/service/icon/s3.png";
import s4 from "../assets/images/service/icon/s4.png";
import s5 from "../assets/images/service/icon/s5.png";
import s6 from "../assets/images/service/icon/s6.png";
import s7 from "../assets/images/service/icon/s7.png";
import s8 from "../assets/images/service/icon/s8.png";

const About = () => {
  return (
    <>
      <section className="about_section">
        <div className="page-header-content">
          <div className="container">
            <div className="page-header-content-inner">
              <div
                className="page-title"
                style={{ textTransform: "capitalize" }}
              >
                <h2>About Us</h2>
              </div>
              <ol className="breadcrumb">
                <li>
                  <a href="index.jsx">Home -</a>
                </li>
                <li className="active">About Us</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      {/* services */}
      <section className="about_services padding-tb">
        <div className="container">
          <div className="section-header animation" data-aos="fade-up">
            <h2>
              <span>We Offer Specialized</span>
            </h2>
            <h2>Services To Meet Your Needs</h2>
          </div>
          <div className="service-wrapper">
            <div className="service_item">
              <div class="service-inner">
                <div class="service-thumb">
                  <img src={s1} alt="feature img" />
                </div>
                <div class="service-content">
                  <h4>Medical Treatment</h4>
                  <p>
                    Specializing in personalized medical treatments tailored to
                    your unique needs, ensuring comprehensive care and support.
                  </p>
                </div>
              </div>
            </div>
            <div className="service_item">
              <div class="service-inner">
                <div class="service-thumb">
                  <img src={s2} alt="feature img" />
                </div>
                <div class="service-content">
                  <h4>Emergency Help</h4>
                  <p>
                    In times of emergency, count on us for swift and expert
                    medical intervention, providing immediate help and
                    reassurance.
                  </p>
                </div>
              </div>
            </div>
            <div className="service_item">
              <div class="service-inner">
                <div class="service-thumb">
                  <img src={s3} alt="feature img" />
                </div>
                <div class="service-content">
                  <h4>Medical Professionals</h4>
                  <p>
                    Experience expert care from our dedicated team of medical
                    professionals, committed to your health and well-being every
                    step of the way.
                  </p>
                </div>
              </div>
            </div>
            <div className="service_item">
              <div class="service-inner">
                <div class="service-thumb">
                  <img src={s4} alt="feature img" />
                </div>
                <div class="service-content">
                  <h4>Qualified Doctors</h4>
                  <p>
                    Our dedicated team of qualified doctors brings expertise,
                    compassion, and commitment to every patient interaction.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* appointment section */}
      <section className="appointment-section">
        <div className="container">
          <div class="section-wrapper">
            <div
              class="appointment-left wow fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay=".1s"
            >
              <div class="time-info">
                <div class="al-title">
                  <h2>
                    <span>24 Hours </span>
                  </h2>
                  <h2>Opening Our Services</h2>
                </div>
                <div class="dep-item">
                  <div class="dep-item-inner">
                    <div class="day-name">Satarday</div>
                    <div class="day-time">8:00 am-10:00 pm</div>
                  </div>
                </div>
                <div class="dep-item">
                  <div class="dep-item-inner">
                    <div class="day-name">Sunday</div>
                    <div class="day-time">6:00 am-8:00 pm</div>
                  </div>
                </div>
                <div class="dep-item">
                  <div class="dep-item-inner">
                    <div class="day-name">Monday</div>
                    <div class="day-time">6:00 am-2:00 pm</div>
                  </div>
                </div>
                <div class="dep-item">
                  <div class="dep-item-inner">
                    <div class="day-name">Tuesday</div>
                    <div class="day-time">7:00 am-9:00 pm</div>
                  </div>
                </div>
                <div class="dep-item">
                  <div class="dep-item-inner">
                    <div class="day-name">Widnessday</div>
                    <div class="day-time">10:00 am-12:00 pm</div>
                  </div>
                </div>
                <div class="dep-item">
                  <div class="dep-item-inner">
                    <div class="day-name">Thirsday</div>
                    <div class="day-time">2:00 am-6:00 pm</div>
                  </div>
                </div>
                <div class="dep-item">
                  <div class="dep-item-inner">
                    <div class="day-name">Friday</div>
                    <div class="day-time">Closed</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="appointment-right fadeInRight">
              <div class="ar-title">
                <h2>
                  <span>Make An</span>
                </h2>
                <h2>Appointment Now</h2>
              </div>
              <form action="https://aminurislam.com/">
                <input
                  type="text"
                  id="fname"
                  name="firstname"
                  placeholder="Your Name"
                />
                <select id="country" name="country">
                  <option value="1">Select Departments</option>
                  <option value="2">Dental</option>
                  <option value="3">U.C</option>
                </select>
                <input
                  type="text"
                  id="lname"
                  name="lastname"
                  placeholder="Phone Number"
                />
                <input
                  type="date"
                  id="start"
                  name="trip-start"
                  value="2021-02-21"
                />
                <button class="lab-btn" type="submit">
                  <span>
                    Appointment Now <i class="icofont-double-right"></i>
                  </span>{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <div class="counter-section padding-tb">
        <div class="container">
          <div class="section-wrapper">
            <div class="counter-item wow fadeInLeft">
              <div class="counter-item-inner">
                <div class="counter-thumb">
                  <img src={s5} alt="counter" />
                </div>
                <div class="counter-content">
                  <p class="post-content">Patients Every Day</p>
                </div>
              </div>
            </div>
            <div class="counter-item wow fadeInLeft">
              <div class="counter-item-inner">
                <div class="counter-thumb">
                  <img src={s6} alt="counter" />
                </div>
                <div class="counter-content">
                  <p class="post-content">Qualified Doctors</p>
                </div>
              </div>
            </div>
            <div class="counter-item wow fadeInRight">
              <div class="counter-item-inner">
                <div class="counter-thumb">
                  <img src={s7} alt="counter" />
                </div>
                <div class="counter-content">
                  <p class="post-content">Years Experience</p>
                </div>
              </div>
            </div>
            <div class="counter-item wow fadeInRight">
              <div class="counter-item-inner">
                <div class="counter-thumb">
                  <img src={s8} alt="counter" />
                </div>
                <div class="counter-content">
                  <p class="post-content">Diagnosis Verity</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
