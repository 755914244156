import React, { useEffect, useState } from "react";
import ortho from "../assets/images/depart/icon/ortho.png";
import obstetrician from "../assets/images/depart/icon/obstetrician.png";
import gynac from "../assets/images/depart/icon/gynac.png";
import surgery from "../assets/images/depart/icon/surgery.png";
import medicine from "../assets/images/depart/icon/medicine.png";
import Anaeasthe from "../assets/images/depart/icon/Anaeasthe.png";
import physio from "../assets/images/depart/icon/physio.png";
import dentist from "../assets/images/depart/icon/dentist.png";
import Pediatrics from "../assets/images/depart/icon/Pediatrics.png";
import icu from "../assets/images/depart/icon/icu.png";
import fUser from "../assets/images/clients/fUser.jpg";
import sUser from "../assets/images/clients/sUser.jpg";
import tUser from "../assets/images/clients/tUser.jpg";
import eUser from "../assets/images/clients/eUser.jpg";
import emergencyService from "../assets/images/service/emergencyService.jpg";
import medicalService from "../assets/images/service/medicalService.jpg";
import ambulance from "../assets/images/icon/ambulance.png";
import medical from "../assets/images/icon/medical.png";
import healthcare from "../assets/images/icon/healthcare.png";
import screen from "../assets/images/icon/screen.png";
import infrastructure from "../assets/images/model/infrastructure.jpg";
import talent from "../assets/images/model/talent.jpg";
import technology from "../assets/images/model/technology.jpg";
import trust from "../assets/images/model/trust.jpg";
import process from "../assets/images/model/process.jpg";
import g1 from "../assets/images/gallery/g1.jpg";
import g2 from "../assets/images/gallery/g2.jpg";
import g3 from "../assets/images/gallery/g3.jpg";
import g4 from "../assets/images/gallery/g4.jpg";
import c1 from "../assets/images/recognition/c1.webp";
import c2 from "../assets/images/recognition/c2.webp";
import c3 from "../assets/images/recognition/c3.webp";
import { Link } from "react-router-dom";
import AOS from "aos";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import "../assets/styles/Home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import axios from "axios";

const Home = () => {
  const departments = [
    {
      name: "Orthopaedic",
      title: "orthopaedic",
      img: ortho,
    },
    {
      name: "Obstetrics",
      title: "obstetrics",
      img: obstetrician,
    },
    {
      name: "Gynaecology",
      title: "gynaecology",
      img: gynac,
    },
    {
      name: "General surgery",
      title: "general-surgery",
      img: surgery,
    },
    {
      name: "Regenerative Medicine",
      title: "regenerative-medicine",
      img: medicine,
    },
    {
      name: "Anaeasthesiology",
      title: "anaeasthesiology",
      img: Anaeasthe,
    },
    {
      name: "Physiotherapy",
      title: "physiotherapy",
      img: physio,
    },
    {
      name: "Dental",
      title: "dental",
      img: dentist,
    },

    {
      name: "Pediatrics",
      title: "pediatrics",
      img: Pediatrics,
    },
    {
      name: "ICU and NICU",
      title: "icu-and-nicu",
      img: icu,
    },
  ];

  const [file, setFile] = useState(null);
  const [imgFile, setImgFile] = useState(null);
  const [modelImage, setModelImage] = useState(infrastructure);
  const [isActive, setIsActive] = useState(false);

  const getDepartments = async () => {
    const resp = await axios.get(
      "https://hospital.advanceorthopaedic.in/api/v1/department-detail/2"
    );
    console.log(resp.data);
  };

  useEffect(() => {
    // AOS.init({ duration: 2000 });
    AOS.init({
      disable: "mobile",
    });
  }, []);

  const handleImageMode = (data) => {
    setModelImage(data);
    // setInterval(() => {
    //   setIsActive(false);
    // }, 2000);
    setIsActive(true);
  };

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsActive(false);
    }, 800);
    // setIsActive(false);
  }, [modelImage]);

  return (
    <>
      {/* home banner section */}

      <section className="banner-section">
        <div className="container">
          <div className="banner-wrapper">
            <div className="banner-content">
              <h2
                className="animation"
                data-wow-duration="2s"
                data-wow-delay=".1s"
                data-aos="fade-down"
              >
                Best Medical Clinic
              </h2>
              <h1
                className="animation"
                data-wow-duration="2s"
                data-wow-delay=".1s"
                data-aos="fade-left"
              >
                <b style={{ color: "#eb1d25" }}>Bringing Health</b> <br /> To
                Life For The sky...
              </h1>
              <Link to="#" className="lab-btn animation">
                <span>
                  Get Appoinments
                  <i
                    className="fa fa-angle-double-right"
                    aria-hidden="true"
                  ></i>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* department */}

      <section className="department-section style-2 padding-tb bg-color">
        <div className="container">
          <div className="section-header animation" data-aos="fade-up">
            <h2>
              <span>We Are The</span>
            </h2>
            <h2>Best Our Departments Center</h2>
          </div>
          <div className="section-wrapper">
            {departments?.map((department, index) => {
              return (
                <div
                  className="lab-item animation"
                  data-aos="fade-up"
                  key={index}
                >
                  <div className="lab-item-inner">
                    <h4>
                      <Link to={`/department/${department.title}`}>
                        {department.name}
                      </Link>
                    </h4>
                    <img src={department.img} alt="" />
                    <Link to={`/department/${department.title}`}>
                      Read More{" "}
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* model of care */}
      <section className="care_model padding-tb">
        <div className="container">
          <div className="section-header animation" data-aos="fade-up">
            <h2>Our Model Of Care</h2>
          </div>
          <div className="section-wrapper">
            <div className="image_outer">
              <div className="image_inner">
                <img
                  src={modelImage}
                  className={`center-image ${isActive ? "isloading" : ""}`}
                  alt=""
                />
                <div
                  className="list_title_a"
                  onClick={() => handleImageMode(infrastructure)}
                >
                  <div className="click-text">
                    <div
                      className={`${
                        modelImage === infrastructure
                          ? "block-head active"
                          : "block-head"
                      }`}
                    >
                      Exceptional clinical talent{" "}
                    </div>
                  </div>

                  <div
                    className={`${
                      modelImage === infrastructure
                        ? "click-circle active"
                        : "click-circle"
                    }`}
                  ></div>
                </div>
                <div
                  className="list_title_b"
                  onClick={() => handleImageMode(talent)}
                >
                  <div className="click-text">
                    <div
                      className={`${
                        modelImage === talent
                          ? "click-circle active"
                          : "click-circle"
                      }`}
                    ></div>
                  </div>

                  <div
                    className={`${
                      modelImage === talent ? "block-head active" : "block-head"
                    }`}
                  >
                    World-class infrastructure{" "}
                  </div>
                </div>
                <div
                  className="list_title_c"
                  onClick={() => handleImageMode(technology)}
                >
                  <div className="click-text">
                    <div
                      className={`${
                        modelImage === technology
                          ? "click-circle active"
                          : "click-circle"
                      }`}
                    ></div>
                  </div>

                  <div
                    className={`${
                      modelImage === technology
                        ? "block-head active"
                        : "block-head"
                    }`}
                  >
                    Latest high-end technology{" "}
                  </div>
                </div>
                <div
                  className="list_title_d"
                  onClick={() => handleImageMode(process)}
                >
                  <div className="click-text">
                    <div
                      className={`${
                        modelImage === process
                          ? "block-head active"
                          : "block-head"
                      }`}
                    >
                      Caring systems and processes Know More{" "}
                    </div>
                  </div>
                  <div
                    className={`${
                      modelImage === process
                        ? "click-circle active"
                        : "click-circle"
                    }`}
                  ></div>
                </div>
                <div
                  className="list_title_e"
                  onClick={() => handleImageMode(trust)}
                >
                  <div className="click-text">
                    <div
                      className={`${
                        modelImage === trust
                          ? "block-head active"
                          : "block-head"
                      }`}
                    >
                      Trust-based compassionate care
                    </div>
                  </div>

                  <div
                    className={`${
                      modelImage === trust
                        ? "click-circle active"
                        : "click-circle"
                    }`}
                  ></div>
                </div>
              </div>
            </div>
            <div className="res_section_wrapper">
              <ul className="resp_lists">
                <li onClick={() => handleImageMode(infrastructure)}>
                  <p> World-class infrastructure</p>
                  <div>
                    {modelImage === infrastructure ? (
                      <RiArrowDropUpLine size={"2em"} />
                    ) : (
                      <RiArrowDropDownLine size={"2em"} />
                    )}
                  </div>
                </li>
                {modelImage === infrastructure && (
                  <div className="resp_image_outer">
                    <div className="resp_image_inner">
                      <img src={modelImage} alt="" />
                    </div>
                  </div>
                )}
                <li onClick={() => handleImageMode(talent)}>
                  <p>Exceptional clinical talent</p>
                  <div>
                    {modelImage === talent ? (
                      <RiArrowDropUpLine size={"2em"} />
                    ) : (
                      <RiArrowDropDownLine size={"2em"} />
                    )}
                  </div>
                </li>
                {modelImage === talent && (
                  <div className="resp_image_outer">
                    <div className="resp_image_inner">
                      <img src={modelImage} alt="" />
                    </div>
                  </div>
                )}
                <li onClick={() => handleImageMode(technology)}>
                  <p> Latest high-end technology</p>
                  <div>
                    {modelImage === technology ? (
                      <RiArrowDropUpLine size={"2em"} />
                    ) : (
                      <RiArrowDropDownLine size={"2em"} />
                    )}
                  </div>
                </li>
                {modelImage === technology && (
                  <div className="resp_image_outer">
                    <div className="resp_image_inner">
                      <img src={modelImage} alt="" />
                    </div>
                  </div>
                )}
                <li onClick={() => handleImageMode(process)}>
                  <p> Caring systems and processes Know More</p>
                  <div>
                    {modelImage === process ? (
                      <RiArrowDropUpLine size={"2em"} />
                    ) : (
                      <RiArrowDropDownLine size={"2em"} />
                    )}
                  </div>
                </li>
                {modelImage === process && (
                  <div className="resp_image_outer">
                    <div className="resp_image_inner">
                      <img src={modelImage} alt="" />
                    </div>
                  </div>
                )}
                <li onClick={() => handleImageMode(trust)}>
                  <p>Trust-based compassionate care</p>
                  <div>
                    {modelImage === trust ? (
                      <RiArrowDropUpLine size={"2em"} />
                    ) : (
                      <RiArrowDropDownLine size={"2em"} />
                    )}
                  </div>
                </li>
                {modelImage === trust && (
                  <div className="resp_image_outer">
                    <div className="resp_image_inner">
                      <img src={modelImage} alt="" />
                    </div>
                  </div>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Services */}
      <section className="our-services padding-tb">
        <div className="container">
          <div className="section-header animation" data-aos="fade-up">
            <h2> Our Services</h2>
          </div>

          <div className="service_card_section">
            <div className="service_card">
              <img src={emergencyService} alt="" className="service-thumb" />
              <div className="card-desk">
                <h3 className="card_title">Emergency Care Services</h3>
                <div className="card-text">
                  <p>
                    Multispecialty hospitals often have well-equipped emergency
                    departments to handle medical emergencies, accidents, and
                    critical conditions.
                  </p>
                </div>
                <div className="card-button dflex space-between centered-items">
                  <div className="common-button">
                    <Link to="/about" className="anchor-button" tabIndex="-1">
                      Know More
                      <span>
                        {" "}
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </span>{" "}
                    </Link>
                  </div>
                  <div className="services-logo">
                    <img src={ambulance} alt="emergency-services" />
                  </div>
                </div>
              </div>
            </div>
            <div className="service_card">
              <div className="card-desk">
                <h3 className="card_title">Diagnostic and Imaging Services</h3>
                <div className="card-text">
                  <p>
                    These hospitals typically offer a comprehensive range of
                    diagnostic services, including laboratory testing, imaging
                    studies (such as X-rays, CT scans, MRI scans, ultrasounds),
                    and other diagnostic procedures.
                  </p>
                </div>
                <div className="card-button dflex space-between centered-items">
                  <div className="common-button">
                    <Link to="/about" className="anchor-button" tabIndex="-1">
                      Know More
                      <span>
                        {" "}
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </span>{" "}
                    </Link>
                  </div>
                  <div className="services-logo">
                    <img src={screen} alt="homecare-logo" />
                  </div>
                </div>
              </div>
              <div className="card-desk">
                <h3 className="card_title">Surgical Services</h3>
                <div className="card-text">
                  <p>
                    Multispecialty hospitals house operating theaters and offer
                    a variety of surgical procedures across different medical
                    specialties. This includes general surgery, orthopedic
                    surgery, neurosurgery, cardiovascular surgery, gynecological
                    surgery, and more.
                  </p>
                </div>
                <div className="card-button">
                  <div className="common-button">
                    <Link to="/about" className="anchor-button" tabIndex="-1">
                      Know More
                      <span>
                        {" "}
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </span>{" "}
                    </Link>
                  </div>
                  <div className="services-logo">
                    <img
                      src={healthcare}
                      alt="homecare-logo"
                      width="100"
                      height="100"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="service_card">
              <img src={medicalService} alt="" className="service-thumb" />
              <div className="card-desk">
                <h3 className="card_title">Specialized Medical Care</h3>
                <div className="card-text">
                  <p>
                    Multispecialty hospitals have departments or units dedicated
                    to various medical specialties, such as cardiology,
                    oncology, neurology, gastroenterology, pulmonology,
                    nephrology, and more.
                  </p>
                </div>
                <div className="card-button dflex space-between centered-items">
                  <div className="common-button">
                    <Link to="/about" className="anchor-button" tabIndex="-1">
                      Know More
                      <span>
                        {" "}
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </span>{" "}
                    </Link>
                  </div>
                  <div className="services-logo">
                    <img src={medical} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Patient Stories */}
      <section className="patient-stories padding-tb">
        <div className="container">
          <div className="section-header animation" data-aos="fade-up">
            <h2>Patient Stories</h2>
          </div>
          <div className="story_container_section">
            <div className="video-col-1">
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio1">
                    <img
                      src={fUser}
                      width="514"
                      height="400"
                      alt="mauritian patient treated for esophageal cancer"
                    />
                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        alt=""
                        width="48"
                        height="48"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">
                      Mauritian Patient Treated For Esophageal Cancer
                    </div>
                    <p>Mr Fazl Hosany</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="video-col-2">
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio2">
                    <img
                      src={sUser}
                      width="514"
                      height="400"
                      alt="liver failure"
                    />

                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        alt="play-icon"
                        width="48"
                        height="48"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">Liver Failure</div>
                    <p>Baby Bhavika</p>
                  </div>
                </div>
              </div>
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio2">
                    <img
                      src={tUser}
                      alt="jaw cancer"
                      width="514"
                      height="400"
                    />

                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        width="48"
                        height="48"
                        alt="play-icon"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">Jaw Cancer</div>
                    <p>Dr. Abhilasha Agarwal </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="video-col-2">
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio2">
                    <img
                      src={eUser}
                      width="514"
                      height="400"
                      alt="liver failure"
                    />

                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        alt="play-icon"
                        width="48"
                        height="48"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">Liver Failure</div>
                    <p>Baby Bhavika</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="video-col-2">
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio2">
                    <img
                      src={sUser}
                      width="514"
                      height="400"
                      alt="liver failure"
                    />

                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        alt="play-icon"
                        width="48"
                        height="48"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">Liver Failure</div>
                    <p>Baby Bhavika</p>
                  </div>
                </div>
              </div>
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio2">
                    <img
                      src={tUser}
                      alt="jaw cancer"
                      width="514"
                      height="400"
                    />

                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        width="48"
                        height="48"
                        alt="play-icon"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">Jaw Cancer</div>
                    <p>Dr. Abhilasha Agarwal </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="video-col-1">
              {" "}
              <div className="video-wrap">
                <div className="video">
                  <div className="video-thumb ratio1">
                    <img
                      src={fUser}
                      width="514"
                      height="400"
                      alt="mauritian patient treated for esophageal cancer"
                    />
                    <div
                      onClick={() =>
                        setFile(
                          "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                        )
                      }
                      className="play-video"
                    >
                      <img
                        src="https://www.medanta.org/assets/images/icons/play-icon.png"
                        alt=""
                        width="48"
                        height="48"
                      />
                    </div>
                  </div>
                  <div className="video-caption">
                    <div className="video-title">
                      Mauritian Patient Treated For Esophageal Cancer
                    </div>
                    <p>Mr Fazl Hosany</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="resp_story_container">
            <Swiper
              spaceBetween={50}
              slidesPerView="1"
              pagination={{
                clickable: true,
              }}
              navigation={true}
              onSlideChange={() => console.log("slide change")}
              modules={[Autoplay, Pagination, Navigation]}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <div className="video-col-1">
                  <div className="video-wrap">
                    <div className="video">
                      <div className="video-thumb ratio1">
                        <img
                          src={fUser}
                          alt="mauritian patient treated for esophageal cancer"
                        />
                        <div
                          onClick={() =>
                            setFile(
                              "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                            )
                          }
                          className="play-video"
                        >
                          <img
                            src="https://www.medanta.org/assets/images/icons/play-icon.png"
                            alt=""
                            width="48"
                            height="48"
                          />
                        </div>
                      </div>
                      <div className="video-caption">
                        <div className="video-title">Mauritian</div>
                        <p>Mr Fazl Hosany</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="video-col-2">
                  <div className="video-wrap">
                    <div className="video">
                      <div className="video-thumb ratio2">
                        <img
                          src={sUser}
                          width="514"
                          height="400"
                          alt="liver failure"
                        />

                        <div
                          onClick={() =>
                            setFile(
                              "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                            )
                          }
                          className="play-video"
                        >
                          <img
                            src="https://www.medanta.org/assets/images/icons/play-icon.png"
                            alt="play-icon"
                            width="48"
                            height="48"
                          />
                        </div>
                      </div>
                      <div className="video-caption">
                        <div className="video-title">Liver Failure</div>
                        <p>Baby Bhavika</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="video-col-2">
                  <div className="video-wrap">
                    <div className="video">
                      <div className="video-thumb ratio2">
                        <img
                          src={eUser}
                          width="514"
                          height="400"
                          alt="liver failure"
                        />

                        <div
                          onClick={() =>
                            setFile(
                              "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                            )
                          }
                          className="play-video"
                        >
                          <img
                            src="https://www.medanta.org/assets/images/icons/play-icon.png"
                            alt="play-icon"
                            width="48"
                            height="48"
                          />
                        </div>
                      </div>
                      <div className="video-caption">
                        <div className="video-title">Liver Failure</div>
                        <p>Baby Bhavika</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="video-col-2">
                  <div className="video-wrap">
                    <div className="video">
                      <div className="video-thumb ratio2">
                        <img
                          src={sUser}
                          width="514"
                          height="400"
                          alt="liver failure"
                        />

                        <div
                          onClick={() =>
                            setFile(
                              "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                            )
                          }
                          className="play-video"
                        >
                          <img
                            src="https://www.medanta.org/assets/images/icons/play-icon.png"
                            alt="play-icon"
                            width="48"
                            height="48"
                          />
                        </div>
                      </div>
                      <div className="video-caption">
                        <div className="video-title">Liver Failure</div>
                        <p>Baby Bhavika</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="video-col-2">
                  <div className="video-wrap">
                    <div className="video">
                      <div className="video-thumb ratio2">
                        <img
                          src={tUser}
                          alt="jaw cancer"
                          width="514"
                          height="400"
                        />

                        <div
                          onClick={() =>
                            setFile(
                              "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                            )
                          }
                          className="play-video"
                        >
                          <img
                            src="https://www.medanta.org/assets/images/icons/play-icon.png"
                            width="48"
                            height="48"
                            alt="play-icon"
                          />
                        </div>
                      </div>
                      <div className="video-caption">
                        <div className="video-title">Jaw Cancer</div>
                        <p>Dr. Abhilasha Agarwal </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="video-col-1">
                  <div className="video-wrap">
                    <div className="video">
                      <div className="video-thumb ratio1">
                        <img
                          src={fUser}
                          width="514"
                          height="400"
                          alt="mauritian patient treated for esophageal cancer"
                        />
                        <div
                          onClick={() =>
                            setFile(
                              "https://www.youtube.com/embed/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"
                            )
                          }
                          className="play-video"
                        >
                          <img
                            src="https://www.medanta.org/assets/images/icons/play-icon.png"
                            alt=""
                            width="48"
                            height="48"
                          />
                        </div>
                      </div>
                      <div className="video-caption">
                        <div className="video-title">
                          Mauritian Patient Treated For Esophageal Cancer
                        </div>
                        <p>Mr Fazl Hosany</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div
            className="popup-media"
            style={{ display: file ? "block" : "none" }}
          >
            <span onClick={() => setFile(null)}>&times;</span>
            {file && (
              // <video
              //   src={"https://youtu.be/Co2f_xdVYkw?si=hOnaevylx7R5VmLe"}
              //   autoPlay
              //   controls
              //   muted
              //   autoPlay
              //   muted
              // ></video>
              <iframe
                width="500px"
                height="315"
                src={file}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            )}
          </div>
        </div>
      </section>

      {/* updates */}
      <section className="home-update updates-section padding-tb">
        <div className="container">
          <div className="section-header">
            <h2>Updates</h2>
          </div>
          <div className="updates-slider cm-flex" id="updates-slider">
            <Swiper
              pagination={{
                type: "fraction",
              }}
              breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 80 },
                480: { slidesPerView: 1, spaceBetween: 150 },
                768: { slidesPerView: 3, spaceBetween: 50 },
                1024: { slidesPerView: 3, spaceBetween: 0 },
              }}
              slidesPerView={3}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="updates-card text-center">
                  <div className="icon mb-20">
                    <center>
                      <img
                        src="https://www.medanta.org/storage/posts/October2023//6xUlBEaEpVCjRNz79LJEsU9tutYyoz-metaRWxkZXIgY2FyZSBwcm9ncmFtbWUucG5n-.png"
                        width="100"
                        height="100"
                        alt="elder-care-program"
                      />
                    </center>
                  </div>
                  <div className="card-heading heading-md mb-10">
                    Elder Care Program
                  </div>
                  <div className="para-wrap mb-20">
                    <p>
                      Medanta’s Elder Care Programme gives your loved ones an
                      empowering plan for their golden years.
                    </p>
                  </div>
                  <div className="text-center">
                    <Link to="#" className="anchor-button" tabIndex="0">
                      Know More <span></span>{" "}
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="updates-card text-center">
                  <div className="icon mb-20">
                    <center>
                      <img
                        src="https://www.medanta.org/storage/posts/October2023//6xUlBEaEpVCjRNz79LJEsU9tutYyoz-metaRWxkZXIgY2FyZSBwcm9ncmFtbWUucG5n-.png"
                        width="100"
                        height="100"
                        alt="elder-care-program"
                      />
                    </center>
                  </div>
                  <div className="card-heading heading-md mb-10">
                    Elder Care Program
                  </div>
                  <div className="para-wrap mb-20">
                    <p>
                      Medanta’s Elder Care Programme gives your loved ones an
                      empowering plan for their golden years.
                    </p>
                  </div>
                  <div className="text-center">
                    <Link to="/" tabIndex="0">
                      Know More <span></span>{" "}
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="updates-card text-center">
                  <div className="icon mb-20">
                    <center>
                      <img
                        src="https://www.medanta.org/storage/posts/October2023//6xUlBEaEpVCjRNz79LJEsU9tutYyoz-metaRWxkZXIgY2FyZSBwcm9ncmFtbWUucG5n-.png"
                        width="100"
                        height="100"
                        alt="elder-care-program"
                      />
                    </center>
                  </div>
                  <div className="card-heading heading-md mb-10">
                    Elder Care Program
                  </div>
                  <div className="para-wrap mb-20">
                    <p>
                      Medanta’s Elder Care Programme gives your loved ones an
                      empowering plan for their golden years.
                    </p>
                  </div>
                  <div className="text-center">
                    <Link to="#" className="anchor-button" tabIndex="0">
                      Know More <span></span>{" "}
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="updates-card text-center">
                  <div className="icon mb-20">
                    <center>
                      <img
                        src="https://www.medanta.org/storage/posts/October2023//6xUlBEaEpVCjRNz79LJEsU9tutYyoz-metaRWxkZXIgY2FyZSBwcm9ncmFtbWUucG5n-.png"
                        width="100"
                        height="100"
                        alt="elder-care-program"
                      />
                    </center>
                  </div>
                  <div className="card-heading heading-md mb-10">
                    Elder Care Program
                  </div>
                  <div className="para-wrap mb-20">
                    <p>
                      Medanta’s Elder Care Programme gives your loved ones an
                      empowering plan for their golden years.
                    </p>
                  </div>
                  <div className="text-center">
                    <Link to="/" className="anchor-button" tabIndex="0">
                      Know More <span></span>{" "}
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      {/* gallery */}

      <section className="gallery_section padding-tb">
        <div className="container">
          <div className="section-header animation" data-aos="fade-up">
            <h2>Gallery</h2>
          </div>

          <div className="gallery_wrapper">
            <Swiper
              spaceBetween={50}
              pagination={{
                type: "fraction",
              }}
              breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 80 },
                480: { slidesPerView: 1, spaceBetween: 150 },
                768: { slidesPerView: 3, spaceBetween: 50 },
                1024: { slidesPerView: 4, spaceBetween: 50 },
              }}
              navigation={true}
              onSlideChange={() => console.log("slide change")}
              modules={[Autoplay, Pagination, Navigation]}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <div className="gallery-thumb">
                  <img
                    src={g1}
                    alt="mauritian patient treated for esophageal cancer"
                    style={{ width: "100%", height: "200px" }}
                    onClick={() => setImgFile(g1)}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="gallery-thumb">
                  <img
                    src={g2}
                    alt="liver failure"
                    style={{ width: "100%", height: "200px" }}
                    onClick={() => setImgFile(g2)}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="gallery-thumb">
                  <img
                    src={g3}
                    alt="liver failure"
                    style={{ width: "100%", height: "200px" }}
                    onClick={() => setImgFile(g3)}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="gallery-thumb">
                  <img
                    src={g4}
                    alt="liver failure"
                    style={{ width: "100%", height: "200px" }}
                    onClick={() => setImgFile(g4)}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="gallery-thumb">
                  <img
                    src={g1}
                    alt="jaw cancer"
                    style={{ width: "100%", height: "200px" }}
                    onClick={() => setImgFile(g4)}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="gallery-thumb">
                  <img
                    src={g2}
                    alt="mauritian patient treated for esophageal cancer"
                    style={{ width: "100%", height: "200px" }}
                    onClick={() => setImgFile(g4)}
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          <div
            className="popup-media"
            style={{ display: imgFile ? "block" : "none" }}
          >
            <span onClick={() => setImgFile(null)}>&times;</span>
            {imgFile && <img src={imgFile} alt="" srcset="" />}
          </div>
        </div>
      </section>

      {/* awards */}

      <section className="awards_section padding-tb">
        <div className="container">
          <div className="section-header animation" data-aos="fade-up">
            <h2>Awards & Recognitions</h2>
          </div>

          <div className="awards_wrapper">
            <Swiper
              spaceBetween={50}
              pagination={{
                type: "fraction",
              }}
              breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 80 },
                480: { slidesPerView: 1, spaceBetween: 150 },
                768: { slidesPerView: 3, spaceBetween: 50 },
                1024: { slidesPerView: 4, spaceBetween: 50 },
              }}
              navigation={true}
              onSlideChange={() => console.log("slide change")}
              modules={[Autoplay, Pagination, Navigation]}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <img
                  src={c1}
                  alt="mauritian patient treated for esophageal cancer"
                  style={{ width: "100%", height: "200px" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={c2}
                  alt="liver failure"
                  style={{ width: "100%", height: "200px" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={c3}
                  alt="liver failure"
                  style={{ width: "100%", height: "200px" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={c1}
                  alt="liver failure"
                  style={{ width: "100%", height: "200px" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={c2}
                  alt="jaw cancer"
                  style={{ width: "100%", height: "200px" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={c3}
                  alt="mauritian patient treated for esophageal cancer"
                  style={{ width: "100%", height: "200px" }}
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      {/* <!-- ==========Newsletter Section Ends Here========== --> */}
      <section className="newsletter-section style-1">
        <div className="container">
          <div className="section-wrapper">
            <div className="left wow animation">
              <div className="news-title">
                <h2>
                  Emergency
                  <i className="fa fa-ambulance" aria-hidden="true"></i>
                </h2>
                <p>
                  <Link to="/">
                    <i
                      className="fa fa-phone"
                      aria-hidden="true"
                      style={{ marginRight: "10px" }}
                    ></i>
                    +91-9006982665
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ==========Newsletter Section Ends Here========== --> */}
    </>
  );
};

export default Home;
