import React from "react";
import "../assets/styles/About.css";
import a1 from "../assets/images/recognition/a1.jpg";
import a2 from "../assets/images/recognition/a2.jpg";
import c1 from "../assets/images/recognition/c1.webp";
import c2 from "../assets/images/recognition/c2.webp";
import c3 from "../assets/images/recognition/c3.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Achievement = () => {
  return (
    <>
      <section className="achievement_section">
        <div className="page-header-content">
          <div className="container">
            <div className="page-header-content-inner">
              <div
                className="page-title"
                style={{ textTransform: "capitalize" }}
              >
                <h2>Achievement-Certificates</h2>
              </div>
              <ol className="breadcrumb">
                <li>
                  <a href="index.jsx">Home -</a>
                </li>
                <li className="active">Achievement-Certificates</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      {/* awards */}

      <section className="awards_section padding-tb">
        <div className="container">
          <div className="section-header animation" data-aos="fade-up">
            <h2>Awards & Recognitions</h2>
          </div>

          <div className="awards_wrapper">
            <Swiper
              spaceBetween={50}
              pagination={{
                type: "fraction",
              }}
              breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 80 },
                480: { slidesPerView: 1, spaceBetween: 150 },
                768: { slidesPerView: 3, spaceBetween: 50 },
                1024: { slidesPerView: 4, spaceBetween: 50 },
              }}
              navigation={true}
              onSlideChange={() => console.log("slide change")}
              modules={[Autoplay, Pagination, Navigation]}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <img
                  src={c1}
                  alt="mauritian patient treated for esophageal cancer"
                  style={{ width: "100%", height: "200px" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={c2}
                  alt="liver failure"
                  style={{ width: "100%", height: "200px" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={c3}
                  alt="liver failure"
                  style={{ width: "100%", height: "200px" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={c1}
                  alt="liver failure"
                  style={{ width: "100%", height: "200px" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={c2}
                  alt="jaw cancer"
                  style={{ width: "100%", height: "200px" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={c3}
                  alt="mauritian patient treated for esophageal cancer"
                  style={{ width: "100%", height: "200px" }}
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      <section className="certificate-section padding-tb">
        <div className="container">
          <div className="certificatet_wrapper">
            <div className="certificate_content">
              <h2>
                <span>Diplomas And Сertificates </span>
              </h2>
              <h2>Of Our Professional Doctors</h2>
              <p>
                It is along establshed fact that areader will bef distracted the
                readabe conten the point inof using lorem ipsum is that has more
                orless normal distrbutions it along establshed fact that areader
                will be distracted the readabe contiente the point inof using
                lorem ipsum is that has more orless normal distrbution conten
                here making it look like readable english.
              </p>
            </div>
            <div className="certificate_sites">
              <div class="certificate-item-inner">
                <div class="certificate-thumb">
                  <img src={a1} alt="certificat" />
                </div>
                <div class="certificate-thumb">
                  <img src={a2} alt="certificat" />
                </div>
                <div class="certificate-thumb">
                  <img src={a1} alt="certificat" />
                </div>
                <div class="certificate-thumb">
                  <img src={a2} alt="certificat" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Achievement;
