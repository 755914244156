import React from "react";
import "../assets/styles/Contact.css";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <section className="contact_section">
        <div className="page-header-content">
          <div className="container">
            <div className="page-header-content-inner">
              <div className="page-title">
                <h2>Contact</h2>
              </div>
              <ol className="breadcrumb">
                <li>
                  <Link to="/">Home - </Link>
                </li>
                <li className="active">Contact</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      {/* contact card */}
      <section className="contact_card padding-tb">
        <div className="container">
          <div className="section_wrapper">
            <div className="contact_wrapper">
              <div className="contact_card_left">
                <div class="contact-info">
                  <h3>Contact Information</h3>
                  <ul className="contact-lists">
                    <li>
                      <h6>Office Address:</h6>
                      <p>
                        Patliputra Station,Infront of PaniTaki Ashiyna
                        Nagar,Patna
                      </p>
                    </li>
                    <li>
                      <h6>Phone Number:</h6>
                      <p> +91-9006982665</p>
                    </li>
                    <li>
                      <h6>Email Address:</h6>
                      <a href="#">aomc@gmail.com</a>
                    </li>
                    <li>
                      <h6>Website Address:</h6>
                      <a href="https://www.advanceorthopaedic.in/">
                        https://www.advanceorthopaedic.in/
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="contact_card_right">
                <div class="here-form">
                  <h3>We Love To Hear From You</h3>
                  <form>
                    <input
                      type="text"
                      id="fname"
                      name="firstname"
                      placeholder="Your Name"
                    />
                    <select id="country" name="country">
                      <option value="1">Select Departments</option>
                      <option value="2">Dental</option>
                      <option value="3">U.C</option>
                    </select>
                    <input
                      type="text"
                      id="lname"
                      name="lastname"
                      placeholder="Phone Number"
                    />
                    <input type="date" />
                    <button class="lab-btn" type="submit">
                      <span>
                        Appointment Now <i class="icofont-double-right"></i>
                      </span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* contact map */}
      <section className="location-map padding-tb">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3652.227736322122!2d90.389177!3d23.739257!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b85c740f17d1%3A0xdd3daab8c90eb11f!2sCodexCoder!5e0!3m2!1sen!2sbd!4v1708088508012!5m2!1sen!2sbd"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
    </>
  );
};

export default Contact;

// https://api.postman.com/collections/21202664-7fe0f73e-d9b1-47a9-a87a-6eb03ffd5f2b?access_key=PMAT-01HR09ENADXX48N4Z2MDKR7XRK
