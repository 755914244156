import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./layout/Navbar";
import Footer from "./layout/Footer";
import Home from "./pages/Home";
import DepartmentDetail from "./components/DepartmentDetail";
import TreatmentDetail from "./components/TreatmentDetail";
import "animate.css";
import "../src/assets/styles/global.css";
import DoctorDetail from "./components/DoctorDetail";
import About from "./pages/About";
import Achievement from "./pages/Achievement";
import Contact from "./pages/Contact";
import Overview from "./pages/Overview";
import Mission from "./pages/Mission";
import Director from "./pages/Director";
import Milestone from "./pages/Milestone";
import Projects from "./pages/Projects";
import Gallery from "./pages/Gallery";
import Career from "./pages/Career";
function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/overview" element={<Overview />} />
        <Route path="/achievement-certificate" element={<Achievement />} />
        <Route path="/mission-vision" element={<Mission />} />
        <Route path="/director-desk" element={<Director />} />
        <Route path="/milestone" element={<Milestone />} />
        <Route path="/hospital-projects" element={<Projects />} />

        <Route path="/department/:slug" element={<DepartmentDetail />} />
        <Route
          path="/department/:cate_slug/treatment/:treat_slug"
          element={<TreatmentDetail />}
        />
        <Route path="/doctor/:data" element={<DoctorDetail />} />
        <Route path="/gallery/:data" element={<Gallery />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
