import React from "react";
import "../assets/styles/About.css";
import mission from "../assets/images/mission-vision/mission.jpg";
import vision from "../assets/images/mission-vision/vision.jpg";
import v1 from "../assets/images/icon/v1.png";
import v2 from "../assets/images/icon/v2.png";
import v3 from "../assets/images/icon/v3.png";
import v4 from "../assets/images/icon/v4.png";

const Mission = () => {
  return (
    <>
      <section className="mission_section">
        <div className="page-header-content">
          <div className="container">
            <div className="page-header-content-inner">
              <div
                className="page-title"
                style={{ textTransform: "capitalize" }}
              >
                <h2>Mission-Vision</h2>
              </div>
              <ol className="breadcrumb">
                <li>
                  <a href="index.jsx">Home -</a>
                </li>
                <li className="active">Mission-Vision</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      {/* mission section */}

      <section className="mission-mid-section padding-tb">
        <div className="container">
          <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
            Our Mission
          </h2>
          <div className="mission_wrapper">
            <div className="mission_desc">
              <p>
                At Advanced Orthopedic and Maternity Center, our mission is to
                provide comprehensive and compassionate care to our patients,
                utilizing the latest advancements in orthopedic and maternity
                medicine. We strive to enhance the quality of life for
                individuals through personalized treatment plans, innovative
                techniques, and a commitment to excellence in healthcare
                delivery. Our dedicated team of medical professionals is driven
                by a passion for improving patient outcomes and ensuring the
                highest standards of safety, effectiveness, and patient
                satisfaction.
              </p>
            </div>
            <div className="mission_thumb">
              <img src={mission} alt="" style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </section>

      {/* vision section */}
      <section className="vision-section padding-tb">
        <div className="container">
          <div className="vision-title">
            <h2>Our Vision</h2>
          </div>
          <div className="vision-wrapper">
            <div className="vision_thumb">
              <img src={vision} alt="" style={{ width: "100%" }} />
            </div>
            <div className="vision_desc">
              <div className="vision_cards">
                <div className="vision_card">
                  <div className="vision_thumb">
                    <img src={v1} alt="" />
                  </div>
                  <div className="vision_head">
                    <h4>Clinical Excellence</h4>
                  </div>
                  <div className="vision_desc_sec">
                    <p>
                      Patient-centered care, evidence-based practice, continuous
                      improvement, compassion, collaboration, innovation.
                    </p>
                  </div>
                </div>
                <div className="vision_card">
                  <div className="vision_thumb">
                    <img src={v2} alt="" />
                  </div>
                  <div className="vision_head">
                    <h4>Innovation and Research</h4>
                  </div>
                  <div className="vision_desc_sec">
                    <p>
                      Pioneering solutions, cutting-edge technology, rigorous
                      methodology, transformative discoveries, interdisciplinary
                      collaboration.
                    </p>
                  </div>
                </div>
                <div className="vision_card">
                  <div className="vision_thumb">
                    <img src={v3} alt="" />
                  </div>
                  <div className="vision_head">
                    <h4>Patient-Centered Care</h4>
                  </div>
                  <div className="vision_desc_sec">
                    <p>
                      Personalized treatment, empathy,
                      <br /> active listening, holistic approach, patient
                      empowerment, continuous support.
                    </p>
                  </div>
                </div>
                <div className="vision_card">
                  <div className="vision_thumb">
                    <img src={v4} alt="" />
                  </div>
                  <div className="vision_head">
                    <h4>Community Impact</h4>
                  </div>
                  <div className="vision_desc_sec">
                    <p>
                      Social engagement, local development, inclusive
                      initiatives, sustainable programs, shared prosperity,
                      lasting change.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Mission;
